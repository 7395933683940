import axios from 'axios';

// Create an Axios instance with default configurations
const axiosInstance = axios.create({
  baseURL: 'https://kannant.pythonanywhere.com/api/v2/',
  // baseURL:'http://127.0.0.1:8000/api/v2/',
  timeout: 10000, // Optional timeout for requests
  headers: {
    'Content-Type': 'application/json', 
    // 'ngrok-skip-browser-warning': 'true'
  },
});
axiosInstance.interceptors.request.use(
  (config) => {
    const token = sessionStorage.getItem('accessToken'); // Get access token from sessionStorage
    if (token) {
      config.headers.Authorization = `Bearer ${token}`;
    }
    return config;
  },
  (error) => Promise.reject(error)
);

axiosInstance.interceptors.response.use(
  (response) => response,
  async (error) => {
    const originalRequest = error.config;

    if (error.response.status === 401 && !originalRequest._retry) {
      originalRequest._retry = true;
      
      // Get the refresh token from sessionStorage
      const refreshToken = sessionStorage.getItem('refreshToken');
      if (refreshToken) {
        try {
          const response = await axios.post('https://kannant.pythonanywhere.com/api/token/refresh/', {
            refresh: refreshToken,
          });
          const newToken = response.data.access;
          const newRefresh = response.data.refresh;
          sessionStorage.setItem('accessToken', newToken); // Update the token in sessionStorage
          sessionStorage.setItem('refreshToken', newRefresh);
          originalRequest.headers.Authorization = `Bearer ${newToken}`;
          console.log(newRefresh,'Refresh Token');
          return axiosInstance(originalRequest);
        } catch (refreshError) {
          // Handle refresh token failure (e.g., redirect to login)
          return Promise.reject(refreshError);
        }
      }
    }

    return Promise.reject(error);
  }
);

export default axiosInstance;
