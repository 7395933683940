import React, { Fragment, useEffect, useState, useRef } from 'react';
import { Container, Row, Col, Card, CardBody, Form, FormGroup, Label, Table, Input, Button,InputGroup ,InputGroupText } from 'reactstrap';
import Select from 'react-select';
import { Breadcrumbs ,H5} from '../../../../AbstractElements';
import HeaderCard from '../../../Common/Component/HeaderCard';
import Swal from 'sweetalert2';
import { IoTrash } from "react-icons/io5";
import DataTable from 'react-data-table-component';
import '../forms.css';
import axiosInstance from '../../../../Auth/AxiosInstance';
import Highlighter from 'react-highlight-words';
import { FaRegEdit,FaSearch } from "react-icons/fa";
import classNames from "classnames";
import { Category, MarginLeft, MarginRight } from '../../../../Constant';
import { MdViewHeadline } from "react-icons/md";

function Taxelement() {
  const [name, setName] = useState('');
  const [categoryName, setCategoryName] = useState('');
  const [loadonInventory, setLoadonInventory] = useState(null);
  const [description, setDescription] = useState('');
  const [under, setUnder] = useState(null);
  const [assign, setAssign] = useState(null);
  const [gstcategory, setGstcategory] = useState(null);
  const [gsttype, setGsttype] = useState(null);
  const [salewarehouse, setSalewarehouse] = useState(null);
  const [categories, setCategories] = useState([]);
  const [clearRows, setClearRows] = useState(false);
  const [selectedIds, setSelectedIds] = useState([]);
  const [isEditing, setIsEditing] = useState(false);
  const [editId, setEditId] = useState(null);
  const [errorMessage, setErrorMessage] = useState(null);
  const [selectOptions, setSelectOptions] = useState([]);
  const [categoryOptions, setCategoryOptions] = useState([]);
  const [impactOptions, setImpactOptions] = useState([]);
  const [abatement, setAbatement] = useState([]);
  const [typeOption, setTypeOptions] = useState([]);
  const [nameError1 , setNameError1] = useState(null);
  const [descriptionError1, setDescriptionError1] = useState(null);
  const [nameError2 , setNameError2] = useState(null);
  const [nameError3 , setNameError3] = useState(null);
  const [descriptionError2, setDescriptionError2] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [loading, setLoading] = useState(true);
  const [searchTerm, setSearchTerm] = useState('');
  const [filteredCategories, setFilteredCategories] = useState([]);
  const [submitbtn, setSubmintbtn] =useState('Save');
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [dropdownCatOpen, setDropdownCatOpen] = useState(false);
  const [rows, setRows] = useState([{ from_date: '', to_date: '', percent: 0 }]);
  const [dropdownOwnOpen, setDropdownOwnOpen] = useState(false);
  const [dropdownPurOpen, setDropdownPurOpen] = useState(false);
  const [hsnOptions, setHsnOptions] = useState([]);
  const [sacOptions, setSacOptions] = useState([]);
  const [dropdownSalOpen, setDropdownSalOpen] = useState(false);
  const [shouldFocusName, setShouldFocusName] = useState(false);
  const [fixedOpen, setFixedOpen] = useState(false);
  const [variableOpen, setVariableOpen] = useState(false);
  const [filteredPaymentBase, setFilteredPaymentBase] = useState([]);
  const [isChecked, setIsChecked] = useState(false);
  const [abModalOpen, setAbModalOpen] = useState(false);
  const [fixedRow, setFixedRow] = useState([]);
  const [variableRow, setVariableRow] = useState([]);
  const [rateType, setRateType] = useState('');
  const cancelButtonRef = useRef(null);
  const submitButtonRef = useRef(null);
  const [viewbutton , setViewbutton] = useState(false);
  const [naturebutton , setNaturebutton] = useState(false);
  const[adabpt,setAdabpt]=useState([])
  const[fnatures,setFnatures]=useState([]);
  const[vnatures,setVnatures]=useState([]);

  const abatementopt = [
    { value: 'yes', label: 'Yes' },
    { value: 'no', label: 'No' }
  ];
  const nameLen = 31;
  const desLen = 51;
  const rowsPerPage = 10;
  const handleAddRow = () => {
    setRows([...rows, { from_date: '', to_date: '', percent: '' }]);
  };
  const handleFixedAddRow = () => {
    setFixedRow([...fixedRow, { from_date: '', to_date: '', percent: '' }]);
  };
  const handleVariableAddRow = () => {
    setVariableRow([...variableRow, { from_date: '', to_date: '', hsn: '', hsn_percent: '', sac: '', sac_percent: '' }]);
  };
  const customStylesTable = {
    header: {
      style: {
        minHeight: '56px', // Adjust header height
        backgroundColor: '#f0f0f0', // Light grey background for the header
        color: '#333', // Text color
      },
    },
    headRow: {
      style: {
        backgroundColor: '#f0f0f0', // Grey color for the table header row
      },
    },
    headCells: {
      style: {
        fontSize: '14px', // Font size for header cells
        fontWeight: 'bold',
        textAlign: 'left',
        color: '#000', // Header text color
      },
    },
    rows: {
      style: {
        minHeight: '48px', // Minimum height of rows
        borderBottom: '1px solid #ddd', // Bottom border for rows
      },
    },
  };
  const popupopStyles ={
    control: (provided, state) => ({
      ...provided,
      borderColor: state.isFocused ? 'blue' : '#afafaf', // Change border color on focus
      boxShadow: state.isFocused ? '0 0 0 1px blue' : 'none', // Optional: Add shadow on focus
      '&:hover': {
          borderColor: state.isFocused ? 'blue' : 'black', // Keep the same color on hover
      },
  }),
    valueContainer: (provided) => ({
      ...provided,
      padding: '0 6px',
    }),
    input: (provided) => ({
      ...provided,
      margin: '0px',
    }),
    indicatorSeparator: () => null,
    indicatorsContainer: (provided) => ({
      ...provided,
      height: '34px',
    }),
    // Add zIndex to ensure dropdown is visible above other elements
    menu: (provided) => ({
      ...provided,
      zIndex: 9999,  // High z-index value
      position: 'absolute',
    }),
  }
  const customStyles = {
    control: (provided, state) => ({
        ...provided,
        width:'100%',
        borderColor: state.isFocused ? 'blue' : '#afafaf', // Change border color on focus
        boxShadow: state.isFocused ? '0 0 0 1px blue' : 'none', // Optional: Add shadow on focus
        '&:hover': {
            borderColor: state.isFocused ? 'blue' : 'black', // Keep the same color on hover
        },
    }),

};
  const paginatedData = Array.isArray(categories) ? categories.slice((currentPage - 1) * rowsPerPage, currentPage * rowsPerPage) : [];
  console.log(filteredPaymentBase)
  const columns = [
    {
      name: 'SN',
      selector: (row, index) => index + 1 + (currentPage - 1) * rowsPerPage,
      width:'70px',
      cell:(row, index) =>(
        <div style={{textAlign: 'left !important',display:"flex", justifyContent:"flex-start !important" ,paddingLeft:"4px"}}>{index + 1 + (currentPage - 1) * rowsPerPage}</div>
      )
    },
    {
      name: 'Tax Element',
      selector: row => row.tax_element,
      // width: '300px',
      cell: row => (
        <div style={{textAlign: 'left !important',display:"flex", justifyContent:"flex-start !important" ,paddingLeft:"4px"}}>
          <Highlighter
            highlightClassName="highlighted-text"
            searchWords={[searchTerm]}
            autoEscape={true}
            textToHighlight={row.tax_element}
        />
        </div>
      ),
    },
    {
      name:'',
      width:'5px',
    },
    {
      name: (
        <div>Description</div>
      ),
      selector: row => row.description,
      cell: row => (
        <div style={{textAlign: 'left !important',display:"flex", justifyContent:"flex-start !important" ,paddingLeft:"12px"}}>
          <Highlighter
            highlightClassName="highlighted-text"
            searchWords={[searchTerm]}
            autoEscape={true}
            textToHighlight={row.description}
        />
        </div>
      ),
    },
    {
      name:(<div>GST Category</div>),
      selector: row => row.gst_category_name,
      cell: row => (
        <div style={{textAlign: 'left !important',display:"flex", justifyContent:"flex-start !important" ,paddingLeft:"12px"}}>
          <Highlighter
            highlightClassName="highlighted-text"
            searchWords={[searchTerm]}
            autoEscape={true}
            textToHighlight={row.gst_category_name}
        />
        </div>
      ),
    },
    {
      name: (<div>GST Type</div>),
      selector: row => row.gst_type_name,
      cell: row => (
        <div style={{textAlign: 'left !important',display:"flex", justifyContent:"flex-start !important" ,paddingLeft:"12px"}}>
          <Highlighter
            highlightClassName="highlighted-text"
            searchWords={[searchTerm]}
            autoEscape={true}
            textToHighlight={row.gst_type_name}
        />
        </div>
      ),
    },
    {
      name: 'Ledger name',
      selector: row => row.ledger_name,
      cell: row => (
        <div style={{textAlign: 'left !important',display:"flex", justifyContent:"flex-start !important" ,paddingLeft:"12px"}}>
          <Highlighter
            highlightClassName="highlighted-text"
            searchWords={[searchTerm]}
            autoEscape={true}
            textToHighlight={row.ledger_name}
        />
        </div>
      ),
      
    },
    {
      name: 'Action',
      center: true,
      width:'100px',
      cell: row => (
        <div className="buttonven">
          <button
            className="vendorupdate"
            color='primary'
            title='Edit'
            onClick={() => handleUpdateClick(row)}
          >
            <FaRegEdit className="vendortrash"/>
          </button>
          <button title='Delete' className="vendordele" onClick={() => handleDeleteCategory(row.id)}>
            <IoTrash className="vendortrash" />
          </button>
        </div>
      ),
    },
  ];


  useEffect(() => {
    if (searchTerm.trim() === '') {
      setFilteredCategories(categories || []); // Ensure categories is an array or empty array
    } else {
      const safeCategories = Array.isArray(categories) ? categories : [];
      const filteredData = safeCategories.filter(item =>
        item.name && item.name.toLowerCase().includes(searchTerm.toLowerCase())
      );
      setFilteredCategories(filteredData);
    }
  }, [searchTerm, currentPage, categories]); // Add categories as a dependency

  useEffect(() => {
    fetchCategories(currentPage);
    // fetchSelectOptions();
    fetchimpactOptions();
    fetchTypeOptions();
    fetchHsnOptions();
    fetchSacOptions();
    fetchLedgerOptions();
    // fetchCategoryOptions();
  }, [currentPage,searchTerm]); 

  const fetchCategories = async (page) => {
    setLoading(true);
    try {
      let response;
      if (searchTerm) {
          response = await axiosInstance.get(`taxation/taxelement/search/?name=${searchTerm}`);
      } else {
          response = await axiosInstance.get(`taxation/taxelement/get/`);
      }
      setCategories(response.data);
      setFilteredCategories(response.data); 
    } catch (error) {
      if (error.response && error.response.status === 404 && page > 1) {
        handlePageChange(page - 1); 
      } else {
        console.error("Error fetching categories:", error);
      }
    }finally {
      setLoading(false);
    }
  };
  const handlePageChange = (page) => {
    setCurrentPage(page);
    fetchCategories(page);
  };
  // const fetchSelectOptions = async (editId = null) => {
  //   try {
  //     const response = await axiosInstance.get('taxation/taxelement/get/');
  //     console.log(editId,'snckdsvv');
  //     const filteredOptions = response.data
  //     .filter(category => category.id !== editId) // Exclude the category being edited
  //     .map(category => ({
  //       value: category.id,
  //       label: category.name
  //     }));
  //     setSelectOptions([{ value: '1', label: '[] Primary' }, ...filteredOptions]);
  //   } catch (error) {
  //     console.error('Error fetching select options:', error);
  //   }
  // };

  // const fetchCategoryOptions = async (editId = null) => {
  //   try {
  //     const response = await axiosInstance.get('generalledger/coaledger/get/');
  //     console.log(editId,'snckdsvv');
  //     const filteredOptions = response.data
  //     .filter(category => category.id !== editId) // Exclude the category being edited
  //     .map(category => ({
  //       value: category.id,
  //       label: category.name
  //     }));
  //     setCategoryOptions([ ...filteredOptions]);
  //   } catch (error) {
  //     console.error('Error fetching select options:', error);
  //   }
  // }; 
  const fetchTypeOptions = async (editId = null) => {
    try {
      const response = await axiosInstance.get('taxation/gsttaxcategory/get/');
      console.log(editId,'snckdsvv');
      const filteredOptions = response.data
      .filter(category => category.id !== editId) // Exclude the category being edited
      .map(category => ({
        value: category.id,
        label: category.name
      }));
      setTypeOptions([ ...filteredOptions]);
    } catch (error) {
      console.error('Error fetching select options:', error);
    }
  }; 
  const fetchHsnOptions = async (editId = null) => {
    try {
      const response = await axiosInstance.get('taxation/hsn/get/');
      console.log(editId,'snckdsvv');
      const filteredOptions = response.data
      .filter(category => category.id !== editId) // Exclude the category being edited
      .map(category => ({
        value: category.id,
        label: category.name
      }));
      setHsnOptions([ ...filteredOptions]);
    } catch (error) {
      console.error('Error fetching select options:', error);
    }
  }; 
  const fetchSacOptions = async (editId = null) => {
    try {
      const response = await axiosInstance.get('taxation/sac/get/');
      console.log(editId,'snckdsvv');
      const filteredOptions = response.data
      .filter(category => category.id !== editId) // Exclude the category being edited
      .map(category => ({
        value: category.id,
        label: category.name
      }));
      setSacOptions([ ...filteredOptions]);
    } catch (error) {
      console.error('Error fetching select options:', error);
    }
  }; 
  const fetchLedgerOptions = async (editId = null) => {
    try {
      const response = await axiosInstance.get('generalledger/coaledger/get/');
      console.log(editId,'snckdsvv');
      const opt = response.data.map(category => ({
        value: category.id,
        label: `${category.account}(${category.name})`
      }));
      console.log(opt,'options');
      const filteredOptions = response.data
      .filter(category => category.id !== editId)
      .map(category => ({
        value: category.id,
        label: `${category.account} -- ${category.name}`
      }));
      setSelectOptions([ ...filteredOptions]);
    } catch (error) {
      console.error('Error fetching select options:', error);
    }
  };
  const fetchimpactOptions = async (editId = null) => {
    try {
      const response = await axiosInstance.get('taxation/gsttaxtype/get/');
      console.log(editId,'snckdsvv');
      const filteredOptions = response.data
      .filter(category => category.id !== editId) // Exclude the category being edited
      .map(category => ({
        value: category.id,
        label: category.name
      }));
      setImpactOptions([ ...filteredOptions]);
    } catch (error) {
      console.error('Error fetching select options:', error);
    }
  }; 
//   const handleSearchChange = (e) => {
//     setSearchTerm(e.target.value);
//     setCurrentPage(1);
// };
  const handleSubmit = async (e) => {
    console.log(rows,'data from added')
    e.preventDefault();
    const payload = {
      tax_element : name,
      description,
      load_on_inventory : loadonInventory,
      abatement : rows,
      fixedNature : fixedRow,
      variableNature : variableRow,
      gst_type : gstcategory ? gstcategory.value : null,
      gst_category : gsttype ? gsttype.value : null,
      ledger : under ? under.value : null,
    };
    setNameError1(null);
    setDescriptionError1(null);
    try {
      if (isEditing) {
        await axiosInstance.put(`taxation/taxelement/${editId}/`, payload);
        setIsEditing(false);
      } else {
        await axiosInstance.post('taxation/taxelement/create/', payload);
        setErrorMessage(null);
      }
      fetchCategories(currentPage);
      fetchimpactOptions();
      fetchTypeOptions();
      fetchHsnOptions();
      fetchSacOptions();
      fetchLedgerOptions();
      // fetchCategoryOptions();
      resetForm();
      Swal.fire({
        title: 'Success!',
        text: 'Tax Element has been saved successfully.',
        icon: 'success',
        confirmButtonText: 'OK'
      });
    } catch (error) {
      console.error('Error:', error);
      if (error.response && error.response.data) {
        const nameError = error.response.data.name && Array.isArray(error.response.data.name) ? error.response.data.name[0] : null;
        const descriptionError = error.response.data.description && Array.isArray(error.response.data.description) ? error.response.data.description[0] : null;
        const existsError = error.response.data.error && Array.isArray(error.response.data.error) ? error.response.data.error[0] : null;
        console.log(nameError, 'Name Error');
        console.log(descriptionError, 'Description Error');
        if (nameError && descriptionError) {
          setNameError1(nameError);
          setDescriptionError1(descriptionError);
        } else if (nameError) {
          setNameError1(nameError)
        } else if (descriptionError) {
          setDescriptionError1(descriptionError);
        } else if (existsError) {
          setErrorMessage(existsError);
 
        }
      } else {
        setErrorMessage('An error occurred while submitting the form.');
      }
    }
  };


  const getAbatment =(abatement)=>{
    if(Array.isArray(abatement) && abatement.length === 0){
      
      setAbatement([{ value: 'no', label: 'No' }]);
    }
    else{
      setAbatement([{ value: 'yes', label: 'Yes' }]);
      setViewbutton(true);
      

    }
  }

  const getfixed = (fixedNature)=>{
    if(Array.isArray(fixedNature) && fixedNature.length >= 1){
      setRateType('fixed')
    }
  }

  const getnature = (variableNature)=>{
    if(Array.isArray(variableNature) && variableNature.length >= 1){
      setRateType('variable')
    }
  }
  // function getdatayn(categories){

  const handleUpdateClick = (category) => {
    setRows([])
    setRateType(' ');
    setEditId(category.id);
    setNameError2(null);
    setDescriptionError2(null);
    setName('');
    setDescription('');
    setIsEditing(false);
    // setUnder(null);
    setNameError1(null);
    setClearRows(!clearRows);
    setSubmintbtn('Save')
    setDescriptionError1(null);
    setErrorMessage('');
    setNameError1(null);
    setDescriptionError1(null);
    setName(category.tax_element);
    setDescription(category.description);
    setSubmintbtn('Update');
    console.log(category.id,'nbhgvcsfdnhvbjmb');
    // setUnder({ value: category.under, label: category.under_name });
    setGstcategory({value: category.gst_category, label: category.gst_category_name});
    setUnder({value: category.ledger, label: category.ledger_name});
    setGsttype({value: category.gst_type, label: category.gst_type_name});
    setLoadonInventory(() => { setIsChecked(true); return category.load_on_inventory; });
    getAbatment(category.abatement);
    getfixed(category.fixedNature);
    getnature(category.variableNature);
    setAdabpt(category.abatement);
    setFnatures(category.fixedNature);
    setVnatures(category.variableNature);
    setNaturebutton(true);
    

    <FormGroup className='formin'>
    <Label style={{fontWeight:"600"}} className="col-form-label">Tax nature</Label>
      
        <div className='m-t-10 m-checkbox-inline custom-radio-ml'>
          <div className='radio radio-primary'>
            <Input id='radioinline1' type='radio' name="rateType" value="fixed" checked={rateType === 'fixed'} onChange={() => handleRateTypeChange('fixed')} />
            <Label className='mb-0' for='radioinline1'>
              {Option}
              <span className='digits'> {'Fixed'}</span>
            </Label>
          </div>
          <div className='radio radio-primary'>
            <Input id='radioinline2' type='radio' name="rateType" value="variable" checked={rateType === 'variable'} onChange={() => handleRateTypeChange('variable')}/>
            <Label className='mb-0' for='radioinline2'>
              {Option}
              <span className='digits'> {'Variable'}</span>
            </Label>
          </div>
        </div>
      
      </FormGroup>

 


  
 
    // setSalewarehouse({value: category.salewarehouse, label: category.salewarehouse_name});
    setIsEditing(true);
    // fetchSelectOptions(category.id);
    fetchimpactOptions(category.id);
    fetchTypeOptions(category.id);
    fetchHsnOptions(category.id);
    fetchSacOptions(category.id);
    // fetchCategoryOptions(category.id);
    setErrorMessage('');
  };

  const resetForm = () => {
    setNameError2(null);
    setNameError3(null);
    setDescriptionError2(null);
    setName('');
    setDescription('');
    setIsEditing(false);
    setUnder(null);
    setGstcategory(null);
    setAssign(null);
    setAbatement(null);
    setIsChecked(false);
    setGsttype(null);
    setSalewarehouse(null);
    setNameError1(null);
    setClearRows(!clearRows);
    setSubmintbtn('Save')
    setDescriptionError1(null);
    setEditId(null);
    setRateType(''); 
    setViewbutton(false);
    setFixedRow([]);
    setVariableRow([]);
    setErrorMessage('');
  };

  const handleCheckboxChange = (selectedRows) => {
    setSelectedIds(selectedRows.selectedRows.map(row => row.id));
  };
  const handleDelete = async () => {
    try {
      const response = await axiosInstance.get('taxation/taxelement/get/');
      const categories = response.data;

      const categoryMap = new Map(categories.map(cat => [cat.id, cat.name]));

      const selectedNames = selectedIds.map(id => categoryMap.get(id)).filter(name => name);

      const escapeHtml = (text) => {
        return text.replace(/[&<>"']/g, (match) => {
          const escape = {
            '&': '&amp;',
            '<': '&lt;',
            '>': '&gt;',
            '"': '&quot;',
            "'": '&#039;'
          };
          return escape[match];
        });
      };

      const namesText = selectedNames.map(name => `<strong>${escapeHtml(name)}</strong>`).join(', ');

      const result = await Swal.fire({
        title: 'Are you sure?',
        html: `You are about to delete the following Item Group: ${namesText}. You won't be able to revert this!`,
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Yes, delete it!'
      });

      if (result.isConfirmed) {
        await axiosInstance.delete('taxation/taxelement/delete/', {
          data: { ids: selectedIds },
        });
        fetchCategories(currentPage);
        setSelectedIds([]);
        // fetchSelectOptions();
        fetchimpactOptions();
        fetchTypeOptions();
        fetchHsnOptions();
        fetchSacOptions();
        fetchLedgerOptions();
        // fetchCategoryOptions();
        resetForm();
      }
    } catch (error) {
      console.error('Error:', error);
      Swal.fire('Error!', 'There was a problem fetching Item Group names or deleting the Item Group.', 'error');
    }
  };

  const handleDeleteCategory = async (id) => {
    const result = await Swal.fire({
      title: 'Are you sure?',
      text: `You won't be able to revert this!`,
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes, delete it!'
    });

    if (result.isConfirmed) {
      try {
        await axiosInstance.delete(`taxation/taxelement/${id}/`);
        fetchCategories(currentPage);
        // Swal.fire('Deleted!', 'Your category has been deleted.', 'success');
      } catch (error) {
        console.error('Error deleting category:', error);
        Swal.fire('Error!', 'There was a problem deleting the Item Group.', 'error');
      }

      setSelectedIds([]);
      // fetchSelectOptions();
      fetchimpactOptions();
      fetchTypeOptions();
      fetchLedgerOptions();
      fetchHsnOptions();
      fetchSacOptions();
      // fetchCategoryOptions();
      resetForm();
    }
  };
  const getHighlightedText = (text, highlight) => {
    if (!text || typeof text !== 'string') {
        return <span>{text}</span>;
    }
    if (!highlight || typeof highlight !== 'string') {
        return <span>{text}</span>; 
    }
    const escapedHighlight = highlight.replace(/[-/\\^$*+?.()|[\]{}]/g, '\\$&');
    const parts = text.split(new RegExp(`(${escapedHighlight})`, 'gi'));
    return (
        <span>
            {parts.map((part, index) =>
                part.toLowerCase() === highlight.toLowerCase() ? (
                    <span key={index} style={{ fontWeight: 'bold' }}>{part}</span>
                ) : (
                    part
                )
            )}
        </span>
    );
  };
  const validateName = (value) => {
    console.log(value,'kjhvckjsdnv');
    
   if (value.match('  ')) {
      setNameError1('Continuous spaces are not allowed ');
      return false;
    }  else if(value.length === 30){
      setNameError2(null);
    }else {
      setNameError1(null);
      return true;
    }
  };

  const handleNameChange = (e) => {
      const value = e.target.value;
      setName(value);
      validateName(value); 
  };
  const handleDescriptionChange = (e) => {
    const value = e.target.value;
    setDescription(value);
};
  const handleEnter = (event) => {
    if (event.target && nameError1 && (event.key === 11 || event.key === 13)) {
      event.target.focus();  
    } 
    else if (event.keyCode === 13 && event.target.form) {
      if (event.target.tagName === 'INPUT' || event.target.tagName === 'TEXTAREA') {
        event.target.value = event.target.value.trim();
      }
      if (dropdownOpen) {
        return; 
      }else if(dropdownCatOpen){
        return;
      }else if(dropdownOwnOpen){
        return;
      }else if(dropdownPurOpen){
        return;
      }else if(dropdownSalOpen){
        return;
      } else {
        const form = event.target.form;
        const index = Array.prototype.indexOf.call(form.elements, event.target);
        const nextElement = form.elements[index + 1];
        if (nextElement) {
          nextElement.focus();
        }
        event.preventDefault();
      }
    }
    const { value } = event.target;
    if (
      (event.key === ' ' && value.length === 0) || 
      (event.key === ' ' && value.endsWith(' ')) || 
      (event.key === ' ' && value.includes('  '))
    ) {
      event.preventDefault();
      return;
    }
  };
  
  const handleArrowNavigation = (event, buttonType) => {
    if (event.key === 'ArrowRight' && buttonType === 'save' && cancelButtonRef.current) {
      cancelButtonRef.current.focus();
    } else if (event.key === 'ArrowLeft' && buttonType === 'cancel' && submitButtonRef.current) {
      submitButtonRef.current.focus();
    }
  };
    const handleBlur = (e) => {
      setNameError2(null);
      setDescriptionError2(null);
      if (e.target.tagName === 'INPUT' || e.target.tagName === 'TEXTAREA') {
        e.target.value = e.target.value.trim();  // Ensures the value is trimmed properly
        
        if (nameError1) {
            e.target.focus();
        }else if (descriptionError1) {
          e.target.focus(); 
        }
      }
    };
    const handleDescriptionFocus = () => {
      // Perform name validation when switching to the description field
      if(isEditing){
        const otherCategories = categories.filter(category => category.id !== editId);
        console.log(otherCategories,'is editing');
        if (Array.isArray(otherCategories) && otherCategories.some((item) => item.tax_element.replace(/\s+/g,'').toLowerCase() === name.replace(/\s+/g,'').toLowerCase())) {
          setShouldFocusName(true);
          setNameError3('Tax Element with this name already exists.');
          setTimeout(() => {
            const nameInput = document.getElementById('nameInput');
            if (nameInput) {
              nameInput.focus(); // Set focus on the input with id 'nameInput'
            }
        }, 0);
        }
        else {
          setNameError3(null);
        }
        
      }else{
        if(Array.isArray(categories) && categories.some((item) => item.tax_element.replace(/\s+/g,'').toLowerCase() === name.replace(/\s+/g,'').trim().toLowerCase())){
          setShouldFocusName(true);
          setNameError3('Tax Element with this name already exists.');
          setTimeout(() => {
            const nameInput = document.getElementById('nameInput');
            if (nameInput) {
              nameInput.focus(); // Set focus on the input with id 'nameInput'
            }
          }, 0);
          return false;
        }else {
          setNameError3(null);
        }
  
      }
    };
    const handleInCheckboxChange = (e) => {
      setIsChecked(e.target.checked); // Update state based on checkbox value
    };
    const handleCancelAbatement = () => {
      const noOption = abatementopt.find(option => option.value === 'no');
      setAbatement(noOption);
      setAbModalOpen(false);  // Close modal
      setViewbutton(false);
    };
    const handleCancelFixed = () => {
      setRateType('');
      setFixedOpen(false);  // Close modal
    };
    const handleCancelVaraible = () => {
      setRateType(''); 
      setVariableOpen(false);
    }
    const handleAbatement = (option) =>{
      setAbatement(option);
      if(option.value === 'yes'){
        setAbModalOpen(true);
        setViewbutton(true);
      }else{
        setRows([]);
        setAbModalOpen(false);
      }
    };
    const handleAbatementsubmit = () => {
      setAbModalOpen(false);
    }
    const handleFixed = ()=>{
      setFixedOpen(false);
      setVariableRow([]);
      
    };
    const handleVariable =() =>{
      setVariableOpen(false);
      setFixedRow([]);
    };
    const handleRowChange = (index, field, value) => {
      const newRows = [...rows];
      newRows[index][field] = value;
      setRows(newRows);
    };
    const handleDeleteRow = (index) => {
      const newRows = [...rows];
      newRows.splice(index, 1); // Remove the row at the specified index
      setRows(newRows);
    };
    const handleFixedDeleteRow = (index) => {
      const newRows = [...fixedRow];
      newRows.splice(index, 1); // Remove the row at the specified index
      setFixedRow(newRows);
    };
    const handlVariableDeleteRow = (index) => {
      const newRows = [...variableRow];
      newRows.splice(index, 1); // Remove the row at the specified index
      setVariableRow(newRows);
    };
    const handleInputChange = (index, event) => {
      const { name, value } = event.target;
      const newRows = [...rows];
      if (name === "percent") {
        newRows[index][name] = parseInt(value, 10); // convert to integer
      } else {
        newRows[index][name] = value; // for other fields, set as string
      }
      
      setRows(newRows);
      console.log(rows,'Added')
  };
  const handleFixedChange = (index, event) => {
    const { name, value } = event.target;
    const newRows = [...fixedRow];
    if (name === "percent") {
      newRows[index][name] = parseInt(value, 10); // convert to integer
    } else {
      newRows[index][name] = value; // for other fields, set as string
    }
    setFixedRow(newRows);
    console.log(fixedRow,'Added')
};
const handleVariableChange = (index, event) => {
  const { name, value } = event.target;
  const newRows = [...fixedRow];
  if (name === "hsn_percent" || name === "sac_percent") {
    newRows[index][name] = parseInt(value, 10); // convert to integer
  } else {
    newRows[index][name] = value; // for other fields, set as string
  }
  setVariableRow(newRows);
  console.log(variableRow,'Added')
};
  const handleRateTypeChange = (type) => {
    setRateType(type);
    if (type === 'variable') {
      setVariableOpen(true);  // Open popup if "Variable" is selected
      setVariableRow([{ from_date: '', to_date: '', hsn: '', hsn_percent: 0, sac: '', sac_percent: 0 }]);
    } else {
      setFixedOpen(true); // Close popup if "Fixed" is selected
      setFixedRow([{ from_date: '', to_date: '', percent: 0 }]);
    }
  };
  const handleSelectHsnChange = (index, selectedOption, name) => {
    const newRows = [...variableRow];
    newRows[index][name] = selectedOption.value;
    setVariableRow(newRows);
  };
  const handleSelectSacChange = (index, selectedOption, name) => {
    const newRows = [...variableRow];
    newRows[index][name] = selectedOption.value;
    setVariableRow(newRows);
  };

const handleclick =()=>{
  console.log(adabpt,"nagasaravanan ")
  
  setAbModalOpen(true)
  
  const newRows = adabpt.map(item => ({
    from_date: item.from_date,
    to_date: item.to_date,
    percent: item.percent
  }));
  setRows(newRows);
}

const handleclick1 =()=>{


  if (Array.isArray(fnatures) && fnatures.length >= 1){ 
  setFixedOpen(true)
  const newRows = fnatures.map(item => ({
    from_date: item.from_date,
    to_date: item.to_date,
    percent: item.percent
  }));
  setFixedRow(newRows);
  }

  if (Array.isArray(vnatures) && vnatures.length >= 1){ 
    setVariableOpen(true)

    const newRows = vnatures.map(item => ({
      from_date: item.from_date,
      to_date: item.to_date,
      hsn: item.hsn,
      hsn_percent: item.hsn_percent,
      sac: item.sac,
      sac_percent: item.sac_percent

     
    }));
    setVariableRow(newRows);
    }

  
}

  return (
    <Fragment>
      <Breadcrumbs mainTitle="Taxation" parent="Taxation" title="Tax Element" />
      <Container fluid={true} className='Container1'>
        <div className='vendordiv1a'>
        <Col sm="12" xl="6" className='vendorcol2a'>
                <Card>
                <HeaderCard title={"Tax Element"} />  
                <CardBody>
                <Form className="theme-form nogird" onSubmit={handleSubmit}>
                  <div className='formdiv1'>
                  <div className='rowForm'>
                      <div>
                      <div className='formcol1'>
                          <FormGroup className='formin'>
                              <Label style={{fontWeight:"600"}} className="col-form-label" >Tax Element</Label>
                              <Input style={{color:"black",borderColor: "#afafaf" ,height:"40px"}} onBlur={handleBlur} maxLength='25' id="nameInput" className={classNames("form-control", "inputfoc", {"is-invalid": nameError1,})} type="text" onKeyDown={handleEnter}  value={name} onChange={handleNameChange} required/>
                              {nameError1 && (
                              <div className="alert alert-danger mt-2">
                                  {nameError1}
                              </div>
                              )}
                              {nameError2 && (
                                  <div className="alert alert-danger mt-2">
                                      {nameError2}
                                  </div>
                              )}
                              {nameError3 && (
                                  <div className="alert alert-danger mt-2">
                                      {nameError3}
                                  </div>
                              )}
                          </FormGroup>
                          <FormGroup className='formin'>
                          <Label style={{fontWeight:"600"}} className="col-form-label" >Description</Label>
                          <Input onFocus={handleDescriptionFocus} maxLength="50" onBlur={handleBlur} onKeyDown={handleEnter} style={{color:"black",borderColor: "#afafaf" ,height:"40px"}} id="desInput" className={classNames("form-control", "inputfoc", {"is-invalid": nameError1,})} type="text"  value={description}  onChange={(e)=>setDescription(e.target.value)} />
                          </FormGroup>
                          {descriptionError1 && (
                          <div className="alert alert-danger">
                              {descriptionError1}
                          </div>
                          )}
                          {descriptionError2 && (
                              <div className="alert alert-danger">
                                  {descriptionError2}
                              </div>
                          )}
                          {errorMessage && (
                              <div className="alert alert-danger">
                                  {errorMessage}
                              </div>
                          )}
                      <FormGroup className='formin'>
                          <Label style={{fontWeight:"600"}} className="col-form-label">GST Category</Label>
                          <Select
                              value={gstcategory}
                              onBlur={handleBlur}
                              onKeyDown={handleEnter}
                              onMenuOpen={() => {
                                setDropdownOwnOpen(true);
                              }}
                              onMenuClose={() => {
                                setDropdownOwnOpen(false);
                              }}
                              onFocus={handleDescriptionFocus}
                              styles={customStyles}
                              onChange={(option) => setGstcategory(option)}
                              
                              options={typeOption}
                              filterOption={(option, inputValue) => 
                                option.label.toLowerCase().startsWith(inputValue.toLowerCase())
                              }
                              formatOptionLabel={(option, { inputValue }) => 
                                getHighlightedText(option.label, inputValue)
                              }
                              required
                          />
                      </FormGroup>
                      <FormGroup className='formin'>
                          <Label style={{fontWeight:"600"}} className="col-form-label">GST Type</Label>
                          <Select
                              value={gsttype}
                              styles={customStyles}
                              onChange={(option) => setGsttype(option)}
                              onMenuOpen={() => {
                                setDropdownPurOpen(true);
                              }}
                              onMenuClose={() => {
                                setDropdownPurOpen(false);
                              }}
                              onBlur={handleBlur}
                              onKeyDown={handleEnter}
                              onFocus={handleDescriptionFocus}
                              options={impactOptions}
                              filterOption={(option, inputValue) => 
                                option.label.toLowerCase().startsWith(inputValue.toLowerCase())
                              }
                              formatOptionLabel={(option, { inputValue }) => 
                                getHighlightedText(option.label, inputValue)
                              }
                              required
                          />
                      </FormGroup>
                      </div>
                      <div className='formcol1'>
                          <FormGroup className='formin'>
                            <div className='checkbox checkbox-dark check1'>
                              <Input id='inline-1' type='checkbox' checked={isChecked} onChange={handleInCheckboxChange}/>
                              <Label for='inline-1' style={{fontWeight:"600"}} className="col-form-label" >
                                {Option}
                                <span className='digits'> {'Load on Inventory'}</span>
                              </Label>
                              {isChecked && (
                                <Input onFocus={handleDescriptionFocus} maxLength="50" onBlur={handleBlur} onKeyDown={handleEnter} style={{color:"black",borderColor: "#afafaf" ,height:"40px"}} id="desInput" className={classNames("form-control", "inputfoc", {"is-invalid": nameError1,})} type="number"  value={loadonInventory}  onChange={(e)=>setLoadonInventory(e.target.value)} />
                              )}
                            </div>
                          </FormGroup>
                          
                      <FormGroup className='formin'>
                      <Label style={{fontWeight:"600"}} className="col-form-label">Ledger account</Label>
                        <Select
                            value={under}
                            onFocus={handleDescriptionFocus}
                            onMenuOpen={() => {
                              setDropdownOpen(true);
                            }}
                            onMenuClose={() => {
                              setDropdownOpen(false);
                            }}
                            styles={customStyles}
                            onChange={(option) => setUnder(option)}
                            onKeyDown={handleEnter}
                            onBlur={handleBlur}
                            options={selectOptions}
                            filterOption={(option, inputValue) => {
                              const label = option.label.toLowerCase();
                              const [account, name] = label.split(' -- '); // Split by ' -- ' to get account and name
                              const cleanAccount = account.trim(); // Ensure account is clean of extra spaces
                              const cleanName = name ? name.trim() : ''; // Clean the name if it exists
                        
                              // Check if either account or name starts with the input value
                              return cleanAccount.startsWith(inputValue.toLowerCase()) || cleanName.startsWith(inputValue.toLowerCase());
                            }}
                            formatOptionLabel={(option, { inputValue }) => 
                              getHighlightedText(option.label, inputValue)
                            }
                            required
                        />
                      </FormGroup>
                      <FormGroup className='formin'>
                          <Label style={{fontWeight:"600"}} className="col-form-label">Is Abatement applicable ?</Label>

                          <div style={{display:"flex"}}>
                          <div style={{width:"90%"}} >
                          <Select
                              style 
                              value={abatement}
                              styles={customStyles}
                              onChange={handleAbatement}
                              onMenuOpen={() => {
                                setDropdownPurOpen(true);
                              }}
                              onMenuClose={() => {
                                setDropdownPurOpen(false);
                              }}
                              onBlur={handleBlur}
                              onKeyDown={handleEnter}
                              onFocus={handleDescriptionFocus}
                              options={abatementopt}
                              filterOption={(option, inputValue) =>
                                option.label.toLowerCase().startsWith(inputValue.toLowerCase())
                              }
                              formatOptionLabel={(option, { inputValue }) =>
                                inputValue ? <span>{option.label}</span> : option.label
                              }
                              required
                          />
                          </div>
                          {viewbutton ? 
                          <div style={{width:"10%",padding:"8px"}}>
                            <button type="button" onClick={handleclick } style={{backgroundColor:"transparent",border:"none"}}><MdViewHeadline style={{fontSize:"20px"}}/></button>
                          
                          </div>: null}
                          

                          </div>
                        
                      </FormGroup>
                      <FormGroup className='formin'>
                        <Label style={{fontWeight:"600"}} className="col-form-label">Tax nature</Label>
                            <div  style={{display:"flex"}}>
                            <div className='m-t-10 m-checkbox-inline custom-radio-ml'>
                              <div className='radio radio-primary'>
                                <Input id='radioinline1' type='radio' name="rateType" value="fixed" checked={rateType === 'fixed'} onChange={() => handleRateTypeChange('fixed')} />
                                <Label className='mb-0' for='radioinline1'>
                                  {Option}
                                  <span className='digits'> {'Fixed'}</span>
                                </Label>
                              </div>
                              <div className='radio radio-primary'>
                                <Input id='radioinline2' type='radio' name="rateType" value="variable" checked={rateType === 'variable'} onChange={() => handleRateTypeChange('variable')}/>
                                <Label className='mb-0' for='radioinline2'>
                                  {Option}
                                  <span className='digits'> {'Variable'}</span>
                                </Label>
                              </div>
                            </div>

                            {naturebutton ? <div style={{width:"10%",padding:"8px"}}>
                              <button type="button" onClick={handleclick1 } style={{backgroundColor:"transparent",border:"none"}}><MdViewHeadline style={{fontSize:"20px",backgroundColor:"transparent"}}/></button>
                            
                            </div> : null}
                            
                            
                          
                            </div>
                            
                          </FormGroup>
                      </div>
                      </div>
                    </div>
                  </div>
                    <div >
                        <div className="mt-3" style={{gap:"10px",display:"flex", justifyContent:"flex-end"}}>
                        <button ref={submitButtonRef} onKeyDown={(event) => handleArrowNavigation(event, 'save')}  className='btn2' color='primary' type='submit' disabled={nameError1 || nameError2 || nameError3 || descriptionError1 || descriptionError2 || (name === '')}>
                              {submitbtn}
                          </button>
                          <button className='btn1'  onClick={resetForm} ref={cancelButtonRef} onKeyDown={(event) => handleArrowNavigation(event, 'cancel')}>
                              Cancel
                          </button>
                        </div>
                      </div>
                </Form>
                </CardBody>
                </Card>
              </Col>
              <Col sm="12" xl="6" className='vendorcol1a'>
                  <Card className=''>
                      <div style={{ display: "flex" }}>
                          <div className='headercl'>
                          <HeaderCard title="Tax Element List" />
                          </div>
                          <div className='selectdel' style={{ display: "flex", alignItems: "center", justifyContent: "flex-end", width: "40%" }}>
                              <Button color="danger" className='btncon' onClick={handleDelete} disabled={selectedIds.length === 0}><IoTrash className="vendortrash1" />Delete</Button>
                          </div>
                      </div>
                  <CardBody>
                      <div className="table-responsive">
                          <InputGroup className="mb-3">
                              <Input
                                  type="text"
                                  placeholder="Search"
                                  value={searchTerm}
                                  onChange={(e) => setSearchTerm(e.target.value)}
                                  className="form-control input-no-animation inputfoc"
                                  style={{borderColor: "#afafaf" }}
                              />
                              <InputGroupText >
                                  <FaSearch />
                              </InputGroupText>
                          </InputGroup>
                          <DataTable
                                columns={columns}
                                data={paginatedData}
                                selectableRows
                                selectableRowsSelected={selectedIds}
                                onSelectedRowsChange={handleCheckboxChange}
                                clearSelectedRows={clearRows}
                                pagination
                                paginationServer
                                customStyles={customStylesTable}
                                striped={false}
                                paginationTotalRows={categories.length}
                                paginationPerPage={rowsPerPage}
                                progressPending={loading}
                                autoWidth={true}
                                paginationComponentOptions={{
                                  noRowsPerPage: true, // Disable option to change rows per page
                                }}
                                onChangePage={handlePageChange} // Handle pagination
                                highlightOnHover
                          />
                      </div>
                  </CardBody>
                </Card>
            </Col>
        </div>
        <div>
          {abModalOpen ?  
          <div className="popup">
            <div className='popup-inner'>
            <HeaderCard title={"Abatement"} /> 
              <Form onSubmit={handleAbatementsubmit} className='popupform'>
                  <Table striped bordered hover responsive>
                      <thead>
                          <tr>
                              <th>From Date</th>
                              <th>To Date</th>
                              <th>Percent</th>
                              <th>Action</th>
                          </tr>
                      </thead>
                      <tbody>
                          {rows.map((row, index) => (
                              <tr key={index}>
                                  <td>
                                      <Input
                                          type="date"
                                          name="from_date"
                                          value={row.from_date}
                                          onChange={(e) => handleInputChange(index, e)}
                                          required
                                      />
                                  </td>
                                  <td>
                                      <Input
                                          type="date"
                                          name="to_date"
                                          value={row.to_date}
                                          onChange={(e) => handleInputChange(index, e)}
                                          required
                                      />
                                  </td>
                                  <td>
                                      <Input
                                          type="number"
                                          name="percent"
                                          value={row.percent}
                                          onChange={(e) => handleInputChange(index, e)}
                                          required
                                      />
                                  </td>
                                  <td>
                                  <button title='Delete' className="vendordele1" onClick={handleDeleteRow}>
                                    <IoTrash className="vendortrash" />
                                  </button>
                                  </td>
                              </tr>
                          ))}
                      </tbody>
                  </Table>
                  <button className='btn1a' onClick={handleAddRow}>Add Row</button>
                  <div className='popbtn'>
                    <Button className='btn2' color='primary' type='submit'>Submit</Button>{' '}
                    <Button className='btn1' type="button" onClick={handleCancelAbatement}>Cancel</Button>
                  </div>
              </Form>
            </div>
          </div>
          :null}
        </div>
        <div>
          {fixedOpen ?  
          <div className="popup">
            <div className='popup-inner'>
            <HeaderCard title={"Fixed Nature"} /> 
              <Form onSubmit={handleFixed} className='popupform'>
                  <Table striped bordered hover responsive>
                      <thead>
                          <tr>
                              <th>From Date</th>
                              <th>To Date</th>
                              <th>Percent</th>
                              <th>Action</th>
                          </tr>
                      </thead>
                      <tbody>
                          {fixedRow.map((row, index) => (
                              <tr key={index}>
                                  <td>
                                      <Input
                                          type="date"
                                          name="from_date"
                                          value={row.from_date}
                                          onChange={(e) => handleFixedChange(index, e)}
                                          required
                                      />
                                  </td>
                                  <td>
                                      <Input
                                          type="date"
                                          name="to_date"
                                          value={row.to_date}
                                          onChange={(e) => handleFixedChange(index, e)}
                                          required
                                      />
                                  </td>
                                  <td>
                                      <Input
                                          type="number"
                                          name="percent"
                                          value={row.percent}
                                          onChange={(e) => handleFixedChange(index, e)}
                                          required
                                      />
                                  </td>
                                  <td>
                                  <button title='Delete' className="vendordele1" onClick={handleFixedDeleteRow}>
                                    <IoTrash className="vendortrash" />
                                  </button>
                                  </td>
                              </tr>
                          ))}
                      </tbody>
                  </Table>
                  <button className='btn1a' onClick={handleFixedAddRow}>Add Row</button>
                  <div className='popbtn'>
                    <Button className='btn2' color='primary' type='submit'>Submit</Button>{' '}
                    <Button className='btn1' type="button" onClick={handleCancelFixed}>Cancel</Button>
                  </div>
              </Form>
            </div>
          </div>
          :null}
        </div>
        <div>
        {variableOpen ? (
        <div className="popup">
          <div className='popup-inner'>
          <HeaderCard title={"Variable Nature"} /> 
            <Form onSubmit={handleVariable} className='popupform'>
              <div className="table-container">
              <Table striped bordered hover responsive>
                <thead>
                  <tr>
                    <th>From Date</th>
                    <th>To Date</th>
                    <th>HSN</th>
                    <th>Percent</th>
                    <th>SAC</th>
                    <th>Percent</th>
                    <th>Action</th>
                  </tr>
                </thead>
                <tbody>
                  {variableRow.map((row, index) => (
                    <tr key={index}>
                      <td>
                        <Input
                          type="date"
                          name="from_date"
                          value={row.from_date}
                          onChange={(e) => handleVariableChange(index, e)}
                          required
                        />
                      </td>
                      <td>
                        <Input
                          type="date"
                          name="to_date"
                          value={row.to_date}
                          onChange={(e) => handleVariableChange(index, e)}
                          required
                        />
                      </td>
                      <td>
                      <Select
                            value={hsnOptions.find(option => option.value === row.hsn)}
                            // onFocus={handleDescriptionFocus}
                            onMenuOpen={() => {
                              setDropdownOpen(true);
                            }}
                            onMenuClose={() => {
                              setDropdownOpen(false);
                            }}
                            styles={popupopStyles}
                            onChange={(option) => handleSelectHsnChange(index, option, 'hsn')}
                            onKeyDown={handleEnter}
                            onBlur={handleBlur}
                            options={hsnOptions}
                            filterOption={(option, inputValue) => 
                              option.label.toLowerCase().startsWith(inputValue.toLowerCase())
                            }
                            formatOptionLabel={(option, { inputValue }) => 
                              getHighlightedText(option.label, inputValue)
                            }
                            required
                        />
                      </td>
                      <td>
                        <Input
                          type="number"
                          name="hsn_percent"
                          value={row.hsn_percent}
                          placeholder="%"
                          onChange={(e) => handleVariableChange(index, e)}
                          required
                        />
                      </td>
                      <td>
                      <Select
                            value={sacOptions.find(option => option.value === row.sac)}
                            // onFocus={handleDescriptionFocus}
                            onMenuOpen={() => {
                              setDropdownOpen(true);
                            }}
                            onMenuClose={() => {
                              setDropdownOpen(false);
                            }}
                            styles={popupopStyles}
                            onChange={(option) => handleSelectSacChange(index, option, 'sac')}
                            onKeyDown={handleEnter}
                            onBlur={handleBlur}
                            options={sacOptions}
                            filterOption={(option, inputValue) => 
                              option.label.toLowerCase().startsWith(inputValue.toLowerCase())
                            }
                            formatOptionLabel={(option, { inputValue }) => 
                              getHighlightedText(option.label, inputValue)
                            }
                            required
                        />
                      </td>
                      <td>
                        <Input
                          type="number"
                          name="sac_percent"
                          value={row.sac_percent}
                          placeholder="%"
                          onChange={(e) => handleVariableChange(index, e)}
                          required
                        />
                      </td>
                      <td>
                        <button title='Delete' className="vendordele1" onClick={() => handlVariableDeleteRow(index)}>
                          <IoTrash className="vendortrash" />
                        </button>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </Table>
              </div>
              <button className='btn1a' onClick={handleVariableAddRow}>Add Row</button>
                  <div className='popbtn'>
                    <Button className='btn2' color='primary' type='submit'>Submit</Button>{' '}
                    <Button className='btn1' type="button" onClick={handleCancelVaraible}>Cancel</Button>
                  </div>
            </Form>
          </div>
        </div>
      ) : null}
        </div>
      </Container>
    </Fragment>
  );
}

export default Taxelement;
