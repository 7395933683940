export const MENUITEMS = [
  {
    menutitle: 'Procurement',
    menucontent: 'Dashboards,Widgets',
    Items: [
      {
        title: 'Master',
        icon: 'sample-page',
        type: 'sub',
        children: [
          {
            active: false,
            path: `/Vendor/`,
            title: 'Vendor',
            match:'Vendor',
            type: 'link',
          },
          {
            active: false,
            path: `/vendorgroup/`,
            title: 'VendorGroup',
            match:'VendorGroup',
            type: 'link',
          },
          {
            active: false,
            path: `/vendorcategory/`,
            // head:'Vendor Category',
            title: 'VendorCategory',
            match: 'VendorCategory',
            type: 'link',
          },
        ],
      },
      {
        title: 'Submaster',
        icon: 'sample-page',
        type: 'sub',
        children: [
          {
            active: false,
            path: `/paymentbase`,
            title: 'PaymentBase',
            match:'PaymentBase',
            type: 'link',
          },
        ],
      },
    ],
  },
  {
    menutitle: 'Inventory',
    menucontent: 'Dashboards,Widgets',
    Items: [
      {
        title: 'Master',
        icon: 'sample-page',
        type: 'sub',
        children: [
          {
            active: false,
            path: `/warehouse`,
            title: 'warehouse',
            match:'warehouse',
            type: 'link',
          },
          {
            active: false,
            path: `/itemcategory`,
            title: 'ItemCategory',
            match:'ItemCategory',
            type: 'link',
          },
          {
            active: false,
            path: `/itemsubcategory`,
            title: 'ItemSubCategory',
            match: 'ItemSubCategory',
            type: 'link',
          },
          {
            active: false,
            path: `/itemgroup`,
            title: 'ItemGroup',
            match:'itemgroup',
            type: 'link',
          },
          {
            active: false,
            path: `/uom`,
            title: 'UOM Form',
            match:'UOM',
            type: 'link',
          },
          {
            active: false,
            path: `/expensecode`,
            title: 'Expense Code',
            match:'Expense Code',
            type: 'link',
          },
        ],
      },
      {
        title: 'Submaster',
        icon: 'sample-page',
        type: 'sub',
        children: [
          {
            active: false,
            path: `/materialclassification`,
            title: 'materialclassification',
            match:'materialclassification',
            type: 'link',
          },
          {
            active: false,
            path: `/materialownership`,
            title: 'materialownership',
            match:'materialownership',
            type: 'link',
          },
          {
            active: false,
            path: `/warehousecategory`,
            title: 'WarehouseCategory',
            match:'WarehouseCategory',
            type: 'link',
          },
          {
            active: false,
            path: `/materialsegregation`,
            title: 'MaterialSegregation',
            match:'MaterialSegregation',
            type: 'link',
          },
        ],
      }
    ],
  },
  {
    menutitle: 'General Ledger',
    menucontent: 'Dashboards,Widgets',
    Items: [
      {
        title: 'Master',
        icon: 'sample-page',
        type: 'sub',
        children: [
          {
            active: false,
            path: `/coagroup`,
            title: 'COAGroup',
            match:'COAGroup',
            type: 'link',
          },
          {
            active: false,
            path: `/coaledger`,
            title: 'COALedger',
            match:'Currency',
            type: 'link',
          },
        ],
      },
      {
        title: 'Submaster',
        icon: 'sample-page',
        type: 'sub',
        children: [
          {
            active: false,
            path: `/coagroupnature`,
            title: 'COAGroupNature',
            match:'COAGroupNature',
            type: 'link',
          },
          {
            active: false,
            path: `/currency`,
            title: 'Currency',
            match:'Currency',
            type: 'link',
          },
        ],
      }
    ],
  },
  {
    menutitle: 'Taxation',
    menucontent: 'Dashboards,Widgets',
    Items: [
      {
        title: 'Master',
        icon: 'sample-page',
        type: 'sub',
        children: [
          {
            active: false,
            path: `/hsn`,
            title: 'HSN',
            match:'HSN',
            type: 'link',
          },
          {
            active: false,
            path: `/sac`,
            title: 'SAC',
            match:'SAC',
            type: 'link',
          },
          {
            active: false,
            path: `/withholdtax`,
            path: `/WithholdTax`,
            title: 'WithholdTax',
            match:'HSN',
            type: 'link',
          },
          {
            active: false,
            path: `/taxelement`,
            title: 'TaxElement',
            match:'TaxElement',
            type: 'link',
          },
        ],
      },
      {
        title: 'Submaster',
        icon: 'sample-page',
        type: 'sub',
        children: [
          {
            active: false,
            path: `/whtaxcategory`,
            title: 'WhTaxcategory',
            match:'WhTaxcategory',
            type: 'link',
          },
          {
            active: false,
            path: `/whtaxtype`,
            title: 'WhTaxType',
            match:'WhTaxType',
            type: 'link',
          },
          {
            active: false,
            path: `/whtaximpact`,
            title: 'WhTaxImpact',
            match:'WhTaxImpact',
            type: 'link',
          },
          {
            active: false,
            path: `/natureofassessee`,
            title: 'NatureOfAssessee',
            match:'NatureOfAssessee',
            type: 'link',
          },
          {
            active: false,
            path: `/registrationtype`,
            title: 'RegistrationType',
            match:'RegistrationType',
            type: 'link',
          },
          {
            active: false,
            path: `/assesseeterritory`,
            title: 'AssesseeTerritory',
            match:'AssesseeTerritory',
            type: 'link',
          },
          {
            active: false,
            path: `/gsttaxcategory`,
            title: 'GstTaxCategory',
            match:'GstTaxCategory',
            type: 'link',
          },
          {
            active: false,
            path: `/gsttaxtype`,
            title: 'GstTaxType',
            match:'GstTaxType',
            type: 'link',
          },
          {
            active: false,
            path: `/gsttaxnature`,
            title: 'GstTaxNature',
            match:'GstTaxNature',
            type: 'link',
          },
          {
            active: false,
            path: `/gstchargeablebasis`,
            title: `GstChargeableBasis`,
            match: `GstChargeableBasis`,
            type: 'link',
          },
        ],
      }
    ],
  },
];


