import React, {Fragment, useEffect, useState, useRef} from "react";
import { Container, Row, Col, Card, CardBody, Form, FormGroup, Label, Table, Input, Button,InputGroup ,InputGroupText } from 'reactstrap';
import Select from 'react-select';
import { Breadcrumbs ,H2, H3, H4, H5} from '../../../../AbstractElements';
import HeaderCard from '../../../Common/Component/HeaderCard';
import Swal from 'sweetalert2';
import { IoTrash } from "react-icons/io5";
import DataTable from 'react-data-table-component';
import '../forms.css';
import axiosInstance from '../../../../Auth/AxiosInstance';
import Highlighter from 'react-highlight-words';
import { FaRegEdit,FaSearch } from "react-icons/fa";
import classNames from "classnames";
import { Category, MarginLeft, MarginRight, MarginTop } from '../../../../Constant';

function Vendor(){
    const [vendorCode, setVendorCode] = useState('');
    const [address, setAddress] = useState('');
    const [city, setCity] = useState('');
    const [state, setState] = useState('');
    const [country, setCountry] = useState('');
    const [pin, setPin] = useState('');
    const [contactPerson, setContactPerson] = useState('');
    const [cp_telephone, setCp_telephone] = useState('');
    const [cp_mobile, setCp_mobile] = useState('');
    const [cp_email, setCp_email] = useState('');
    const [accountsPerson, setAccountPerson] = useState('');
    const [ap_telephone, setAp_telephone] = useState('');
    const [ap_mobile, setAp_mobile] = useState('');
    const [ap_email, setAp_email] = useState('');
    const [vendorLocation, setVendorLocation] = useState('');
    const [gstin, setGstin] = useState('');
    const [transporterid, setTransporterid] = useState('');
    const [paymentTerms, setPaymentTerms] = useState('');
    const [paymentMode, setPaymentMode] = useState('');
    const [creditDays, setCreditDays] = useState('');
    const [paymentBase, setPaymentBase] = useState('');
    const [accountName, setAccountName] = useState('');
    const [accountNumber, setAccountNumber] = useState('');
    const [ifsc, setIfsc] = useState('');
    const [bankName, setBankName] = useState('');
    const [pan, setPan] = useState('');
    const [rateType, setRateType] = useState('');
    const [vendorcategory, setVendorCategory] = useState(null);
    const [transporter, setTransporter] = useState([]);
    const [venCategoryopt, setVenCategoryopt] = useState([]);
    const [VendorName, setVendorName] = useState('');
    const [vendorGroup, setVendorGroup] = useState(null);
    const [venGroupopt, setVenGroupopt] = useState([]);
    const [registrationType, setRegistrationType] = useState(null);
    const [regTypeOpt, setRegTypeOpt] = useState([]);
    const [assesTerritory, setAssesTerritory] = useState(null);
    const [assesterOpt, setAssesteropt] = useState([]);
    const [paymentFrom, setPaymentFrom] = useState([]);
    const [currency, setCurrency] = useState([]);
    const [currencyopt, setCurrencyopt] = useState(null);
    const [calwhtax, setCalwhtax] = useState([]);
    const [natureasses, setNatureasses] = useState([]);
    const [natureopt, setNatureopt] = useState(null);
    const [dropdownCatOpen, setDropdownCatOpen] = useState(false);
    const [dropdownRegOpen, setDropdownRegOpen] = useState(false);
    const [dropdownAssesOpen, setDropdownassesOpen] = useState(false);
    const [dropdownTransOpen, setDropdownTransOpen] = useState(false);
    const [dropdownFromOpen, setDropdownFromOpen] = useState(false);
    const [dropdownCurrOpen, setDropdownCurrOpen] = useState(false);
    const [dropdownWhholdOpen, setDropdownWhholdOpen] = useState(false);
    const [dropdownNatuAssOpen, setDropdownNatuAssOpen] = useState(false);
    const [dropdownOpen, setDropdownOpen] = useState(false);
    const [nameError1 , setNameError1] = useState(null);
    const [currentPage, setCurrentPage] = useState(1);
    const [nameError2 , setNameError2] = useState(null);
    const [nameError3 , setNameError3] = useState(null);
    const [isEditing, setIsEditing] = useState(false);
    const [shouldFocusName, setShouldFocusName] = useState(false);
    const [descriptionError1, setDescriptionError1] = useState(null);
    const [descriptionError2, setDescriptionError2] = useState(null);
    const [errorMessage, setErrorMessage] = useState(null);
    const [vendor, setVendor] = useState([]);
    const [filteredVendor, setFilteredVendor] = useState([]);
    const [searchTerm, setSearchTerm] = useState('');
    const [loading, setLoading] = useState(true);
    const [editId, setEditId] = useState(null);

    const transporteropt = [
        { value: 'yes', label: 'Yes' },
        { value: 'no', label: 'No' }
      ];
    const calwhtaxopt = [
        { value: 'yes', label: 'Yes' },
        { value: 'no', label: 'No' }
    ]
    const paymentfromopt = [
        {value:'Vendor Invoice Date', label:'Vendor Invoice Date'},
        {value:'Material Invoice Date', label:'Material Invoice Date'}
    ]
    const customStyles = {
        control: (provided, state) => ({
            ...provided,
            borderColor: state.isFocused ? 'blue' : '#afafaf', // Change border color on focus
            boxShadow: state.isFocused ? '0 0 0 1px blue' : 'none', // Optional: Add shadow on focus
            '&:hover': {
                borderColor: state.isFocused ? 'blue' : 'black', // Keep the same color on hover
            },
        }),
    };
    const handlePageChange = (page) => {
        setCurrentPage(page);
        fetchVendor(page);
    };
    useEffect(() => {
        fetchVendCatOptions();
        fetchVendGroOptions();
        fetchRegTypeOptions();
        fetchAssterOptions();
        fetchCurrencyOptions();
      }, []);

      const handleSubmit = async (e) => {
        e.preventDefault();
        const payload = {
            vendorcategory: vendorcategory? vendorcategory.value : null,
        };
        setNameError1(null);
        setDescriptionError1(null);
        try {
          if (isEditing) {
            await axiosInstance.put(`taxation/taxelement/${editId}/`, payload);
            setIsEditing(false);
          } else {
            await axiosInstance.post('taxation/taxelement/create/', payload);
            setErrorMessage(null);
          }
          fetchNatureassesOptions();
          fetchAssterOptions();
          fetchCurrencyOptions();
          fetchRegTypeOptions();
          fetchVendCatOptions();
          fetchVendGroOptions();
          // fetchCategoryOptions();
          resetForm();
          Swal.fire({
            title: 'Success!',
            text: 'Tax Element has been saved successfully.',
            icon: 'success',
            confirmButtonText: 'OK'
          });
        } catch (error) {
          console.error('Error:', error);
          if (error.response && error.response.data) {
            const nameError = error.response.data.name && Array.isArray(error.response.data.name) ? error.response.data.name[0] : null;
            const descriptionError = error.response.data.description && Array.isArray(error.response.data.description) ? error.response.data.description[0] : null;
            const existsError = error.response.data.error && Array.isArray(error.response.data.error) ? error.response.data.error[0] : null;
            console.log(nameError, 'Name Error');
            console.log(descriptionError, 'Description Error');
            if (nameError && descriptionError) {
              setNameError1(nameError);
              setDescriptionError1(descriptionError);
            } else if (nameError) {
              setNameError1(nameError)
            } else if (descriptionError) {
              setDescriptionError1(descriptionError);
            } else if (existsError) {
              setErrorMessage(existsError);
     
            }
          } else {
            setErrorMessage('An error occurred while submitting the form.');
          }
        }
      };
    const fetchVendCatOptions = async (editId = null) => {
        try {
          const response = await axiosInstance.get('vendor/categories/get/');
          console.log(editId,'snckdsvv');
          const filteredOptions = response.data
          .filter(category => category.id !== editId) // Exclude the category being edited
          .map(category => ({
            value: category.id,
            label: category.name
          }));
          setVenCategoryopt([ ...filteredOptions]);
        } catch (error) {
          console.error('Error fetching select options:', error);
        }
    }; 
    const fetchVendGroOptions = async (editId = null) => {
        try {
          const response = await axiosInstance.get('vendor/group/get/');
          console.log(editId,'snckdsvv');
          const filteredOptions = response.data
          .filter(category => category.id !== editId) // Exclude the category being edited
          .map(category => ({
            value: category.id,
            label: category.name
          }));
          setVenGroupopt([ ...filteredOptions]);
        } catch (error) {
          console.error('Error fetching select options:', error);
        }
    }; 
    const fetchRegTypeOptions = async (editId = null) => {
        try {
          const response = await axiosInstance.get('taxation/registrationtype/get/');
          console.log(editId,'snckdsvv');
          const filteredOptions = response.data
          .filter(category => category.id !== editId) // Exclude the category being edited
          .map(category => ({
            value: category.id,
            label: category.name
          }));
          setRegTypeOpt([ ...filteredOptions]);
        } catch (error) {
          console.error('Error fetching select options:', error);
        }
    }; 
    const fetchAssterOptions = async (editId = null) => {
        try {
          const response = await axiosInstance.get('taxation/registrationtype/get/');
          console.log(editId,'snckdsvv');
          const filteredOptions = response.data
          .filter(category => category.id !== editId) // Exclude the category being edited
          .map(category => ({
            value: category.id,
            label: category.name
          }));
          setAssesteropt([ ...filteredOptions]);
        } catch (error) {
          console.error('Error fetching select options:', error);
        }
    }; 
    const fetchCurrencyOptions = async (editId = null) => {
        try {
          const response = await axiosInstance.get('generalledger/generalcurrency/get/');
          console.log(editId,'snckdsvv');
          const filteredOptions = response.data
          .filter(category => category.id !== editId) // Exclude the category being edited
          .map(category => ({
            value: category.id,
            label: category.name
          }));
          setCurrencyopt([ ...filteredOptions]);
        } catch (error) {
          console.error('Error fetching select options:', error);
        }
    }; 
    const fetchNatureassesOptions = async (editId = null) => {
        try {
          const response = await axiosInstance.get('taxation/natureofassessee/get/');
          console.log(editId,'snckdsvv');
          const filteredOptions = response.data
          .filter(category => category.id !== editId) // Exclude the category being edited
          .map(category => ({
            value: category.id,
            label: category.name
          }));
          setNatureopt([ ...filteredOptions]);
        } catch (error) {
          console.error('Error fetching select options:', error);
        }
    }; 
    const getHighlightedText = (text, highlight) => {
        if (!text || typeof text !== 'string') {
            return <span>{text}</span>;
        }
        if (!highlight || typeof highlight !== 'string') {
            return <span>{text}</span>; 
        }
        const escapedHighlight = highlight.replace(/[-/\\^$*+?.()|[\]{}]/g, '\\$&');
        const parts = text.split(new RegExp(`(${escapedHighlight})`, 'gi'));
        return (
            <span>
                {parts.map((part, index) =>
                    part.toLowerCase() === highlight.toLowerCase() ? (
                        <span key={index} style={{ fontWeight: 'bold' }}>{part}</span>
                    ) : (
                        part
                    )
                )}
            </span>
        );
    };
    const handleEnter = (event) => {
        if (event.target && nameError1 && (event.key === 11 || event.key === 13)) {
          event.target.focus();  
        } 
        else if (event.keyCode === 13 && event.target.form) {
          if (event.target.tagName === 'INPUT' || event.target.tagName === 'TEXTAREA') {
            event.target.value = event.target.value.trim();
          }
          if (dropdownOpen) {
            return; 
          }else if(dropdownCatOpen){
            return;
          }else if(dropdownRegOpen){
            return;
          }else if(dropdownAssesOpen){
            return;
          }else if(dropdownTransOpen){
            return;
          }else if(dropdownFromOpen){
            return;
          }else if(dropdownCurrOpen){
            return;
          }else if(dropdownWhholdOpen){
            return;
          }else if(dropdownNatuAssOpen){
            return;
          }     else {
            const form = event.target.form;
            const index = Array.prototype.indexOf.call(form.elements, event.target);
            const nextElement = form.elements[index + 1];
            if (nextElement) {
              nextElement.focus();
            }
            event.preventDefault();
          }
        }
        const { value } = event.target;
        if (
          (event.key === ' ' && value.length === 0) || 
          (event.key === ' ' && value.endsWith(' ')) || 
          (event.key === ' ' && value.includes('  '))
        ) {
          event.preventDefault();
          return;
        }
    };
    const handleBlur = (e) => {
        setNameError2(null);
        setDescriptionError2(null);
        if (e.target.tagName === 'INPUT' || e.target.tagName === 'TEXTAREA') {
          e.target.value = e.target.value.trim();  // Ensures the value is trimmed properly
          
          if (nameError1) {
              e.target.focus();
          }else if (descriptionError1) {
            e.target.focus(); 
          }
        }
      };
      const fetchVendor = async (page) => {
        setLoading(true);
        try {
          let response;
          if (searchTerm) {
              response = await axiosInstance.get(`taxation/taxelement/search/?name=${searchTerm}`);
          } else {
              response = await axiosInstance.get(`taxation/taxelement/get/`);
          }
          setVendor(response.data);
          setFilteredVendor(response.data); 
        } catch (error) {
          if (error.response && error.response.status === 404 && page > 1) {
            handlePageChange(page - 1); 
          } else {
            console.error("Error fetching categories:", error);
          }
        }finally {
          setLoading(false);
        }
      };
      const handleDescriptionFocus = () => {
        // Perform name validation when switching to the description field
        if(isEditing){
          const otherVendor = vendor.filter(vendors => vendors.id !== editId);
          console.log(otherVendor,'is editing');
          if (Array.isArray(otherVendor) && otherVendor.some((item) => item.VendorName.replace(/\s+/g,'').toLowerCase() === VendorName.replace(/\s+/g,'').toLowerCase())) {
            setShouldFocusName(true);
            setNameError3('Tax Element with this name already exists.');
            setTimeout(() => {
              const nameInput = document.getElementById('nameInput');
              if (nameInput) {
                nameInput.focus(); // Set focus on the input with id 'nameInput'
              }
          }, 0);
          }
          else {
            setNameError3(null);
          }
          
        }else{
          if(Array.isArray(vendor) && vendor.some((item) => item.VendorName.replace(/\s+/g,'').toLowerCase() === VendorName.replace(/\s+/g,'').trim().toLowerCase())){
            setShouldFocusName(true);
            setNameError3('Tax Element with this name already exists.');
            setTimeout(() => {
              const nameInput = document.getElementById('nameInput');
              if (nameInput) {
                nameInput.focus(); // Set focus on the input with id 'nameInput'
              }
            }, 0);
            return false;
          }else {
            setNameError3(null);
          }
    
        }
      };
    const resetForm = () => {
        setVendorName('');
        setRateType('');
    }
    return(
        <Fragment>
            <Breadcrumbs mainTitle="Vendor" parent="Procurment" title="Vendor" />
                <Container fluid={true} className='Container1'>
                    <div className='vendordiv1a'>
                        <Col sm="12" xl="6" className='vendorcol2a'>
                            <Card>
                                <Form className="theme-form">
                                    <HeaderCard title={"Vendor"} /> 
                                    <div className='formdiv1b'>
                                        <div className='rowForm1'>
                                            <div className='formcol1'>
                                                <FormGroup className='formin1'>
                                                    <Label style={{fontWeight:"600"}} className="col-form-label">Vendor Code</Label>
                                                    <Input style={{color:"black",borderColor: "#afafaf" ,height:"40px"}}/>
                                                </FormGroup>
                                                <FormGroup className='formin1'>
                                                    <Label style={{fontWeight:"600"}} className="col-form-label">Vendor Name</Label>
                                                    <Input style={{color:"black",borderColor: "#afafaf" ,height:"40px"}}/>
                                                </FormGroup>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="h5">
                                        <H5 className='h5'>Mailing Details</H5>
                                    </div>
                                    <div className='formdiv1'>
                                        <div className='rowForm1'>
                                            <div className='formcol1'>
                                                <FormGroup className='formin2'>
                                                    <Label style={{fontWeight:"600"}} className="col-form-label">Address</Label>
                                                    <Input style={{color:"black",borderColor: "#afafaf" ,height:"132px"}} type="textarea"/>
                                                </FormGroup>
                                               <div className='formin2'>
                                                <FormGroup>
                                                        <Label style={{fontWeight:"600"}} className="col-form-label">City</Label>
                                                        <Input style={{color:"black",borderColor: "#afafaf" ,height:"40px"}}/>
                                                    </FormGroup>
                                                    <FormGroup>
                                                        <Label style={{fontWeight:"600"}} className="col-form-label">State</Label>
                                                        <Input style={{color:"black",borderColor: "#afafaf" ,height:"40px"}}/>
                                                    </FormGroup>
                                               </div>
                                               <div className='formin2'>
                                                <FormGroup >
                                                        <Label style={{fontWeight:"600"}} className="col-form-label">Country</Label>
                                                        <Input style={{color:"black",borderColor: "#afafaf" ,height:"40px"}}/>
                                                    </FormGroup>
                                                    <FormGroup>
                                                        <Label style={{fontWeight:"600"}} className="col-form-label">Pincode</Label>
                                                        <Input style={{color:"black",borderColor: "#afafaf" ,height:"40px"}}/>
                                                    </FormGroup>
                                               </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="h5">
                                        <H5 className='h5'>Contact Information</H5>
                                    </div>
                                    <div className='formdiv1'>
                                        <div className='rowForm1'>
                                            <div className='formcol1'>
                                                <FormGroup className='formin2'>
                                                    <Label style={{fontWeight:"600"}} className="col-form-label">Contact Person</Label>
                                                    <Input style={{color:"black",borderColor: "#afafaf" ,height:"40px"}} type="text"/>
                                                </FormGroup>
                                                <FormGroup className='formin2'>
                                                    <Label style={{fontWeight:"600"}} className="col-form-label">Telephone</Label>
                                                    <Input style={{color:"black",borderColor: "#afafaf" ,height:"40px"}}/>
                                                </FormGroup>
                                                <FormGroup className='formin2'>
                                                    <Label style={{fontWeight:"600"}} className="col-form-label">Mobile</Label>
                                                    <Input style={{color:"black",borderColor: "#afafaf" ,height:"40px"}}/>
                                                </FormGroup>
                                            </div>
                                            <div className='formcol1'>
                                                <FormGroup className='formin2'>
                                                    <Label style={{fontWeight:"600"}} className="col-form-label">E-mail</Label>
                                                    <Input style={{color:"black",borderColor: "#afafaf" ,height:"40px"}} type="text"/>
                                                </FormGroup>
                                                <FormGroup className='formin2'>
                                                    <Label style={{fontWeight:"600"}} className="col-form-label">Accounts Person</Label>
                                                    <Input style={{color:"black",borderColor: "#afafaf" ,height:"40px"}}/>
                                                </FormGroup>
                                                <FormGroup className='formin2'>
                                                    <Label style={{fontWeight:"600"}} className="col-form-label">Telephone</Label>
                                                    <Input style={{color:"black",borderColor: "#afafaf" ,height:"40px"}}/>
                                                </FormGroup>
                                            </div>
                                            <div className='formcol1'>
                                                <FormGroup className='formin1'>
                                                    <Label style={{fontWeight:"600"}} className="col-form-label">Mobile</Label>
                                                    <Input style={{color:"black",borderColor: "#afafaf" ,height:"40px"}} type="text"/>
                                                </FormGroup>
                                                <FormGroup className='formin1'>
                                                    <Label style={{fontWeight:"600"}} className="col-form-label">E-mail</Label>
                                                    <Input style={{color:"black",borderColor: "#afafaf" ,height:"40px"}}/>
                                                </FormGroup>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="h5">
                                        <H5 className='h5'>Grouping</H5>
                                    </div>
                                    <div className='formdiv1'>
                                        <div className='rowForm1'>
                                            <div className='formcol1'>
                                                <FormGroup className='formin2 '>
                                                    <Label style={{fontWeight:"600"}} className="col-form-label">Vendor Location</Label>
                                                    <div className='m-t-10 m-checkbox-inline custom-radio-ml' style={{display:"flex", paddingLeft:"10px"}}>  
                                                        <div className='radio radio-primary'>
                                                            <Input id='radioinline1' type='radio' name="rateType" value="domestic_vendor" onChange={(e)=>setVendorLocation(e.target.value)} checked={rateType === 'domestic_vendor'} />
                                                            <Label className='mb-0' for='radioinline1'>
                                                            {Option}
                                                            <span className='digits'> {'Domestic Vendor'}</span>
                                                            </Label>
                                                        </div>
                                                        <div className='radio radio-primary'>
                                                            <Input id='radioinline2' type='radio' name="rateType" value="foreign_vendor" onChange={(e)=>setVendorLocation(e.target.value)} checked={rateType === 'foreign_vendor'}/>
                                                            <Label className='mb-0' for='radioinline2'>
                                                            {Option}
                                                            <span className='digits'> {'Foreign Vendor'}</span>
                                                            </Label>
                                                        </div>
                                                    </div>
                                                </FormGroup>
                                                <FormGroup className='formin2'>
                                                    <Label style={{fontWeight:"600"}} className="col-form-label">Vendor Group</Label>
                                                    <Select
                                                        value={vendorGroup}
                                                        onBlur={handleBlur}
                                                        onKeyDown={handleEnter}
                                                        onMenuOpen={() => {
                                                            setDropdownOpen(true);
                                                        }}
                                                        onMenuClose={() => {
                                                            setDropdownOpen(false);
                                                        }}
                                                        onFocus={handleDescriptionFocus}
                                                        styles={customStyles}
                                                        onChange={(option) => setVendorGroup(option)}
                                                        
                                                        options={venGroupopt}
                                                        filterOption={(option, inputValue) => 
                                                            option.label.toLowerCase().startsWith(inputValue.toLowerCase())
                                                        }
                                                        formatOptionLabel={(option, { inputValue }) => 
                                                            getHighlightedText(option.label, inputValue)
                                                        }
                                                        required
                                                    />
                                                </FormGroup>
                                                <FormGroup className='formin2'>
                                                    <Label style={{fontWeight:"600"}} className="col-form-label">Vendor Category</Label>
                                                    <Select
                                                        value={vendorcategory}
                                                        onBlur={handleBlur}
                                                        onKeyDown={handleEnter}
                                                        onMenuOpen={() => {
                                                            setDropdownCatOpen(true);
                                                        }}
                                                        onMenuClose={() => {
                                                            setDropdownCatOpen(false);
                                                        }}
                                                        onFocus={handleDescriptionFocus}
                                                        styles={customStyles}
                                                        onChange={(option) => setVendorCategory(option)}
                                                        
                                                        options={venCategoryopt}
                                                        filterOption={(option, inputValue) => 
                                                            option.label.toLowerCase().startsWith(inputValue.toLowerCase())
                                                        }
                                                        formatOptionLabel={(option, { inputValue }) => 
                                                            getHighlightedText(option.label, inputValue)
                                                        }
                                                        required
                                                    />
                                                </FormGroup>
                                            </div>
                                            <div className='formcol1'>
                                                
                                            </div>
                                        </div>
                                    </div>
                                    <div className="h5">
                                        <H5 className='h5'>Tax Registration Details</H5>
                                    </div>
                                    <div className='formdiv1'>
                                        <div className='rowForm1'>
                                            <div className='formcol1'>
                                                <FormGroup className='formin2'>
                                                    <Label style={{fontWeight:"600"}} className="col-form-label">Registration type</Label>
                                                    <Select
                                                        value={registrationType}
                                                        onBlur={handleBlur}
                                                        onKeyDown={handleEnter}
                                                        onMenuOpen={() => {
                                                            setDropdownRegOpen(true);
                                                        }}
                                                        onMenuClose={() => {
                                                            setDropdownRegOpen(false);
                                                        }}
                                                        onFocus={handleDescriptionFocus}
                                                        styles={customStyles}
                                                        onChange={(option) => setRegistrationType(option)}
                                                        
                                                        options={regTypeOpt}
                                                        filterOption={(option, inputValue) => 
                                                            option.label.toLowerCase().startsWith(inputValue.toLowerCase())
                                                        }
                                                        formatOptionLabel={(option, { inputValue }) => 
                                                            getHighlightedText(option.label, inputValue)
                                                        }
                                                        required
                                                    />
                                                </FormGroup>
                                                <FormGroup className='formin2'>
                                                    <Label style={{fontWeight:"600"}} className="col-form-label">GSTIN</Label>
                                                    <Input style={{color:"black",borderColor: "#afafaf" ,height:"40px"}}/>
                                                </FormGroup>
                                                <FormGroup className='formin2'>
                                                    <Label style={{fontWeight:"600"}} className="col-form-label">Assessee Territory</Label>
                                                    <Select
                                                        value={assesTerritory}
                                                        onBlur={handleBlur}
                                                        onKeyDown={handleEnter}
                                                        onMenuOpen={() => {
                                                            setDropdownassesOpen(true);
                                                        }}
                                                        onMenuClose={() => {
                                                            setDropdownassesOpen(false);
                                                        }}
                                                        onFocus={handleDescriptionFocus}
                                                        styles={customStyles}
                                                        onChange={(option) => setAssesTerritory(option)}
                                                        
                                                        options={assesterOpt}
                                                        filterOption={(option, inputValue) => 
                                                            option.label.toLowerCase().startsWith(inputValue.toLowerCase())
                                                        }
                                                        formatOptionLabel={(option, { inputValue }) => 
                                                            getHighlightedText(option.label, inputValue)
                                                        }
                                                        required
                                                    />
                                                </FormGroup>
                                            </div>
                                            <div className='formcol1'>
                                                <FormGroup className='formin1'>
                                                    <Label style={{fontWeight:"600"}} className="col-form-label">Is a Transporter</Label>
                                                    <Select
                                                        value={transporter}
                                                        onBlur={handleBlur}
                                                        onKeyDown={handleEnter}
                                                        onMenuOpen={() => {
                                                            setDropdownTransOpen(true);
                                                        }}
                                                        onMenuClose={() => {
                                                            setDropdownTransOpen(false);
                                                        }}
                                                        onFocus={handleDescriptionFocus}
                                                        styles={customStyles}
                                                        onChange={(option) => setTransporter(option)}
                                                        
                                                        options={transporteropt}
                                                        filterOption={(option, inputValue) => 
                                                            option.label.toLowerCase().startsWith(inputValue.toLowerCase())
                                                        }
                                                        formatOptionLabel={(option, { inputValue }) => 
                                                            getHighlightedText(option.label, inputValue)
                                                        }
                                                        required
                                                    />
                                                </FormGroup>
                                                <FormGroup className='formin1'>
                                                    <Label style={{fontWeight:"600"}} className="col-form-label">Transporter ID</Label>
                                                    <Input style={{color:"black",borderColor: "#afafaf" ,height:"40px"}}/>
                                                </FormGroup>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="h5">
                                        <H5 className='h5'>Payment Terms</H5>
                                    </div>
                                    <div className='formdiv1'>
                                        <div className='rowForm1'>
                                            <div className='formcol1'>
                                                <FormGroup className='formin2'>
                                                    <Label style={{fontWeight:"600"}} className="col-form-label">Terms of payment</Label>
                                                    <Input style={{color:"black",borderColor: "#afafaf" ,height:"132px"}} type="textarea"/>
                                                </FormGroup>
                                               <div className='formin2'>
                                                <FormGroup>
                                                        <Label style={{fontWeight:"600"}} className="col-form-label">Mode of payment</Label>
                                                        <Input style={{color:"black",borderColor: "#afafaf" ,height:"40px"}}/>
                                                    </FormGroup>
                                                    <FormGroup>
                                                        <Label style={{fontWeight:"600"}} className="col-form-label">Credit days</Label>
                                                        <Input style={{color:"black",borderColor: "#afafaf" ,height:"40px"}}/>
                                                    </FormGroup>
                                               </div>
                                               <div className='formin2'>
                                                <FormGroup >
                                                        <Label style={{fontWeight:"600"}} className="col-form-label">From</Label>
                                                        <Select
                                                        value={paymentFrom}
                                                        onBlur={handleBlur}
                                                        onKeyDown={handleEnter}
                                                        onMenuOpen={() => {
                                                            setDropdownFromOpen(true);
                                                        }}
                                                        onMenuClose={() => {
                                                            setDropdownFromOpen(false);
                                                        }}
                                                        onFocus={handleDescriptionFocus}
                                                        styles={customStyles}
                                                        onChange={(option) => setPaymentFrom(option)}
                                                        
                                                        options={paymentfromopt}
                                                        filterOption={(option, inputValue) => 
                                                            option.label.toLowerCase().startsWith(inputValue.toLowerCase())
                                                        }
                                                        formatOptionLabel={(option, { inputValue }) => 
                                                            getHighlightedText(option.label, inputValue)
                                                        }
                                                        required
                                                    />
                                                    </FormGroup>
                                                    <FormGroup>
                                                        <Label style={{fontWeight:"600"}} className="col-form-label">Currency</Label>
                                                        <Select
                                                            value={currency}
                                                            onBlur={handleBlur}
                                                            onKeyDown={handleEnter}
                                                            onMenuOpen={() => {
                                                                setDropdownCurrOpen(true);
                                                            }}
                                                            onMenuClose={() => {
                                                                setDropdownCurrOpen(false);
                                                            }}
                                                            onFocus={handleDescriptionFocus}
                                                            styles={customStyles}
                                                            onChange={(option) => setCurrency(option)}
                                                            
                                                            options={currencyopt}
                                                            filterOption={(option, inputValue) => 
                                                                option.label.toLowerCase().startsWith(inputValue.toLowerCase())
                                                            }
                                                            formatOptionLabel={(option, { inputValue }) => 
                                                                getHighlightedText(option.label, inputValue)
                                                            }
                                                            required
                                                        />
                                                    </FormGroup>
                                               </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="h5">
                                        <H5 className='h5'>Bank Details</H5>
                                    </div>
                                    <div className='formdiv1'>
                                        <div className='rowForm1'>
                                            <div className='formcol1'>
                                                <FormGroup className='formin1'>
                                                    <Label style={{fontWeight:"600"}} className="col-form-label">Account Name</Label>
                                                    <Input style={{color:"black",borderColor: "#afafaf" ,height:"40px"}} type="text"/>
                                                </FormGroup>
                                                <FormGroup className='formin1'>
                                                    <Label style={{fontWeight:"600"}} className="col-form-label">Account No.</Label>
                                                    <Input style={{color:"black",borderColor: "#afafaf" ,height:"40px"}}/>
                                                </FormGroup>
                                            </div>
                                            <div className='formcol1'>
                                                <FormGroup className='formin1'>
                                                    <Label style={{fontWeight:"600"}} className="col-form-label">IFS Code</Label>
                                                    <Input style={{color:"black",borderColor: "#afafaf" ,height:"40px"}}/>
                                                </FormGroup>
                                                <FormGroup className='formin1'>
                                                    <Label style={{fontWeight:"600"}} className="col-form-label">Bank Name</Label>
                                                    <Input style={{color:"black",borderColor: "#afafaf" ,height:"40px"}}/>
                                                </FormGroup>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="h5">
                                        <H5 className='h5'>Statutory Details</H5>
                                    </div>
                                    <div className='formdiv1'>
                                        <div className='rowForm1'>
                                            <div className='formcol1'>
                                                <FormGroup className='formin2'>
                                                    <Label style={{fontWeight:"600"}} className="col-form-label">PAN / IT No.</Label>
                                                    <Input style={{color:"black",borderColor: "#afafaf" ,height:"40px"}} type="text"/>
                                                </FormGroup>
                                                <FormGroup className='formin2'>
                                                    <Label style={{fontWeight:"600"}} className="col-form-label">Calculate withholding tax</Label>
                                                    <Select
                                                            value={calwhtax}
                                                            onBlur={handleBlur}
                                                            onKeyDown={handleEnter}
                                                            onMenuOpen={() => {
                                                                setDropdownWhholdOpen(true);
                                                            }}
                                                            onMenuClose={() => {
                                                                setDropdownWhholdOpen(false);
                                                            }}
                                                            onFocus={handleDescriptionFocus}
                                                            styles={customStyles}
                                                            onChange={(option) => setCalwhtax(option)}
                                                            
                                                            options={calwhtaxopt}
                                                            filterOption={(option, inputValue) => 
                                                                option.label.toLowerCase().startsWith(inputValue.toLowerCase())
                                                            }
                                                            formatOptionLabel={(option, { inputValue }) => 
                                                                getHighlightedText(option.label, inputValue)
                                                            }
                                                            required
                                                        />
                                                </FormGroup>
                                                <FormGroup className='formin2'>
                                                    <Label style={{fontWeight:"600"}} className="col-form-label">Nature of assessee</Label>
                                                    <Select
                                                            value={natureasses}
                                                            onBlur={handleBlur}
                                                            onKeyDown={handleEnter}
                                                            onMenuOpen={() => {
                                                                setDropdownNatuAssOpen(true);
                                                            }}
                                                            onMenuClose={() => {
                                                                setDropdownNatuAssOpen(false);
                                                            }}
                                                            onFocus={handleDescriptionFocus}
                                                            styles={customStyles}
                                                            onChange={(option) => setNatureasses(option)}
                                                            
                                                            options={natureopt}
                                                            filterOption={(option, inputValue) => 
                                                                option.label.toLowerCase().startsWith(inputValue.toLowerCase())
                                                            }
                                                            formatOptionLabel={(option, { inputValue }) => 
                                                                getHighlightedText(option.label, inputValue)
                                                            }
                                                            required
                                                        />
                                                </FormGroup>
                                            </div>
                                        </div>
                                    </div>
                                </Form> 
                            </Card>
                        </Col>
                    </div>
                </Container>
        </Fragment>
    );
}
export default Vendor;