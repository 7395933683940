import React, { Fragment, useEffect, useState, useRef } from 'react';
import { Container, Row, Col, Card, CardBody, Form, FormGroup, Label, Input, Table, CardHeader, Button,InputGroup ,InputGroupText} from 'reactstrap';
import Select from 'react-select';
import { Breadcrumbs } from '../../../../AbstractElements';
import HeaderCard from '../../../Common/Component/HeaderCard';
import Swal from 'sweetalert2';
import { IoTrash } from "react-icons/io5";
import DataTable from 'react-data-table-component';
import '../forms.css';
import axiosInstance from '../../../../Auth/AxiosInstance';
import Highlighter from 'react-highlight-words';
import { FaRegEdit,FaSearch } from "react-icons/fa";
import classNames from "classnames";
import { Category, MarginLeft, MarginRight } from '../../../../Constant';

function ItemGroup() {
  const [name, setName] = useState('');
  const [categoryName, setCategoryName] = useState('');
  const [description, setDescription] = useState('');
  const [under, setUnder] = useState(null);
  const [materialsegregation, setMaterialsegregation] = useState(null);
  const [materialownership, setMaterialownership] = useState(null);
  const [purchasewarehouse, setPurchasewarehouse] = useState(null);
  const [salewarehouse, setSalewarehouse] = useState(null);
  const [categories, setCategories] = useState([]);
  const [clearRows, setClearRows] = useState(false);
  const [selectedIds, setSelectedIds] = useState([]);
  const [isEditing, setIsEditing] = useState(false);
  const [editId, setEditId] = useState(null);
  const [errorMessage, setErrorMessage] = useState(null);
  const [selectOptions, setSelectOptions] = useState([{ value: '1', label: '[] Primary' }]);
  const [segregationOptions, setSegregationOptions] = useState([]);
  const [purwarehouseOptions, setPurwarehouseOptions] = useState([]);
  const [salewarehouseOptions, setSalewarehouseOptions] = useState([]);
  const [ownershipOption, setOwnershipOptions] = useState([]);
  const [nameError1 , setNameError1] = useState(null);
  const [descriptionError1, setDescriptionError1] = useState(null);
  const [nameError2 , setNameError2] = useState(null);
  const [nameError3 , setNameError3] = useState(null);
  const [descriptionError2, setDescriptionError2] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [loading, setLoading] = useState(true);
  const [searchTerm, setSearchTerm] = useState('');
  const [filteredCategories, setFilteredCategories] = useState([]);
  const [submitbtn, setSubmintbtn] =useState('Save');
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [dropdownSegOpen, setDropdownSegOpen] = useState(false);
  const [dropdownOwnOpen, setDropdownOwnOpen] = useState(false);
  const [dropdownPurOpen, setDropdownPurOpen] = useState(false);
  const [dropdownSalOpen, setDropdownSalOpen] = useState(false);
  const [shouldFocusName, setShouldFocusName] = useState(false);
  const cancelButtonRef = useRef(null);
  const submitButtonRef = useRef(null);
  const nameLen = 31;
  const desLen = 51;
  const rowsPerPage = 10;
  const customStylesTable = {
    header: {
      style: {
        minHeight: '56px', // Adjust header height
        backgroundColor: '#f0f0f0', // Light grey background for the header
        color: '#333', // Text color
      },
    },
    headRow: {
      style: {
        backgroundColor: '#f0f0f0', // Grey color for the table header row
      },
    },
    headCells: {
      style: {
        fontSize: '14px', // Font size for header cells
        fontWeight: 'bold',
        textAlign: 'left',
        color: '#000', // Header text color
      },
    },
    rows: {
      style: {
        minHeight: '48px', // Minimum height of rows
        borderBottom: '1px solid #ddd', // Bottom border for rows
      },
    },
  };
  
  const customStyles = {
    control: (provided, state) => ({
        ...provided,
        borderColor: state.isFocused ? 'blue' : '#afafaf', // Change border color on focus
        boxShadow: state.isFocused ? '0 0 0 1px blue' : 'none', // Optional: Add shadow on focus
        '&:hover': {
            borderColor: state.isFocused ? 'blue' : 'black', // Keep the same color on hover
        },
    }),
};
  const paginatedData = Array.isArray(categories) ? categories.slice((currentPage - 1) * rowsPerPage, currentPage * rowsPerPage) : [];
  const columns = [
    {
      name: 'SN',
      selector: (row, index) => index + 1 + (currentPage - 1) * rowsPerPage,
      width:'70px',
      cell:(row, index) =>(
        <div style={{textAlign: 'left !important',display:"flex", justifyContent:"flex-start !important" ,paddingLeft:"4px"}}>{index + 1 + (currentPage - 1) * rowsPerPage}</div>
      )
    },
    {
      name: 'Item Group',
      selector: row => row.name,
      width: '300px',
      cell: row => (
        <div style={{textAlign: 'left !important',display:"flex", justifyContent:"flex-start !important" ,paddingLeft:"4px"}}>
          <Highlighter
            highlightClassName="highlighted-text"
            searchWords={[searchTerm]}
            autoEscape={true}
            textToHighlight={row.name}
        />
        </div>
      ),
    },
    {
      name:'',
      width:'5px',
    },
    {
      name: 'Under',
      selector: row => row.under_name,
      cell: row => (
        <div style={{textAlign: 'left !important',display:"flex", justifyContent:"flex-start !important" ,paddingLeft:"12px"}}>
          <Highlighter
            highlightClassName="highlighted-text"
            searchWords={[searchTerm]}
            autoEscape={true}
            textToHighlight={row.under_name}
        />
        </div>
      ),
    },
    {
      name: (
        <div style={{textAlign:"left"}}>Material Segragation</div>
      ),
      selector: row => row.materialsegregation_name,
      cell: row => (
        <div style={{textAlign: 'left !important',display:"flex", justifyContent:"flex-start !important" ,paddingLeft:"12px"}}>
          <Highlighter
            highlightClassName="highlighted-text"
            searchWords={[searchTerm]}
            autoEscape={true}
            textToHighlight={row.materialsegregation_name}
        />
        </div>
      ),
    },
    {
      name:(<div style={{textAlign:"left"}}>Material Ownership</div>),
      selector: row => row.materialownership_name,
      cell: row => (
        <div style={{textAlign: 'left !important',display:"flex", justifyContent:"flex-start !important" ,paddingLeft:"12px"}}>
          <Highlighter
            highlightClassName="highlighted-text"
            searchWords={[searchTerm]}
            autoEscape={true}
            textToHighlight={row.materialownership_name}
        />
        </div>
      ),
    },
    {
      name: (<div style={{textAlign:"left"}}>Purchase Warehouse</div>),
      selector: row => row.purchasewarehouse_name,
      cell: row => (
        <div style={{textAlign: 'left !important',display:"flex", justifyContent:"flex-start !important" ,paddingLeft:"12px"}}>
          <Highlighter
            highlightClassName="highlighted-text"
            searchWords={[searchTerm]}
            autoEscape={true}
            textToHighlight={row.purchasewarehouse_name}
        />
        </div>
      ),
    },
    {
      name:(<div style={{textAlign:"left"}}>Sale Warehouse</div>),
      selector: row => row.salewarehouse_name,
      cell: row => (
        <div style={{textAlign: 'left !important',display:"flex", justifyContent:"flex-start !important" ,paddingLeft:"12px"}}>
          <Highlighter
            highlightClassName="highlighted-text"
            searchWords={[searchTerm]}
            autoEscape={true}
            textToHighlight={row.salewarehouse_name}
        />
        </div>
      ),
    },
    {
      name: 'Description',
      selector: row => row.description,
      cell: row => (
        <div style={{textAlign: 'left !important',display:"flex", justifyContent:"flex-start !important" ,paddingLeft:"12px"}}>
          <Highlighter
            highlightClassName="highlighted-text"
            searchWords={[searchTerm]}
            autoEscape={true}
            textToHighlight={row.description}
        />
        </div>
      ),
      
    },
    {
      name: 'Action',
      center: true,
      width:'100px',
      cell: row => (
        <div className="buttonven">
          <button
            className="vendorupdate"
            color='primary'
            title='Edit'
            onClick={() => handleUpdateClick(row)}
          >
            <FaRegEdit className="vendortrash"/>
          </button>
          <button title='Delete' className="vendordele" onClick={() => handleDeleteCategory(row.id)}>
            <IoTrash className="vendortrash" />
          </button>
        </div>
      ),
    },
  ];


  useEffect(() => {
    if (searchTerm.trim() === '') {
      setFilteredCategories(categories || []); // Ensure categories is an array or empty array
    } else {
      const safeCategories = Array.isArray(categories) ? categories : [];
      const filteredData = safeCategories.filter(item =>
        item.name && item.name.toLowerCase().includes(searchTerm.toLowerCase())
      );
      setFilteredCategories(filteredData);
    }
  }, [searchTerm, currentPage, categories]); // Add categories as a dependency

  useEffect(() => {
    fetchCategories(currentPage);
    fetchSelectOptions();
    fetchpsaleWarehouseOptions();
    fetchpurWarehouseOptions();
    fetchOwnershipOptions();
    fetchSegregationOptions();
  }, [currentPage,searchTerm]); 

  const fetchCategories = async (page) => {
    setLoading(true);
    try {
      let response;
      if (searchTerm) {
          response = await axiosInstance.get(`inventory/itemgroup/search/?name=${searchTerm}`);
      } else {
          response = await axiosInstance.get(`inventory/itemgroup/get/`);
      }
      setCategories(response.data);
      setFilteredCategories(response.data); 
    } catch (error) {
      if (error.response && error.response.status === 404 && page > 1) {
        handlePageChange(page - 1); // If the page doesn't exist, go to the previous page
      } else {
        console.error("Error fetching categories:", error);
      }
    }finally {
      setLoading(false);
    }
  };
  const handlePageChange = (page) => {
    setCurrentPage(page);
    fetchCategories(page);
  };
  const fetchSelectOptions = async (editId = null) => {
    try {
      const response = await axiosInstance.get('inventory/itemgroup/get/');
      console.log(editId,'snckdsvv');
      const filteredOptions = response.data
      .filter(category => category.id !== editId) // Exclude the category being edited
      .map(category => ({
        value: category.id,
        label: category.name
      }));
      setSelectOptions([{ value: '1', label: '[] Primary' }, ...filteredOptions]);
    } catch (error) {
      console.error('Error fetching select options:', error);
    }
  };

  const fetchSegregationOptions = async (editId = null) => {
    try {
      const response = await axiosInstance.get('inventory/materialsegregation/get/');
      console.log(editId,'snckdsvv');
      const filteredOptions = response.data
      .filter(category => category.id !== editId) // Exclude the category being edited
      .map(category => ({
        value: category.id,
        label: category.name
      }));
      setSegregationOptions([ ...filteredOptions]);
    } catch (error) {
      console.error('Error fetching select options:', error);
    }
  }; 
  const fetchOwnershipOptions = async (editId = null) => {
    try {
      const response = await axiosInstance.get('inventory/materialownership/get/');
      console.log(editId,'snckdsvv');
      const filteredOptions = response.data
      .filter(category => category.id !== editId) // Exclude the category being edited
      .map(category => ({
        value: category.id,
        label: category.name
      }));
      setOwnershipOptions([ ...filteredOptions]);
    } catch (error) {
      console.error('Error fetching select options:', error);
    }
  }; 

  const fetchpurWarehouseOptions = async (editId = null) => {
    try {
      const response = await axiosInstance.get('inventory/warehouse/get/');
      console.log(editId,'snckdsvv');
      const filteredOptions = response.data
      .filter(category => category.id !== editId) // Exclude the category being edited
      .map(category => ({
        value: category.id,
        label: category.name
      }));
      setPurwarehouseOptions([ ...filteredOptions]);
    } catch (error) {
      console.error('Error fetching select options:', error);
    }
  }; 
  const fetchpsaleWarehouseOptions = async (editId = null) => {
    try {
      const response = await axiosInstance.get('inventory/warehouse/get/');
      console.log(editId,'snckdsvv');
      const filteredOptions = response.data
      .filter(category => category.id !== editId) // Exclude the category being edited
      .map(category => ({
        value: category.id,
        label: category.name
      }));
      setSalewarehouseOptions([ ...filteredOptions]);
    } catch (error) {
      console.error('Error fetching select options:', error);
    }
  }; 

  const handleSubmit = async (e) => {
    e.preventDefault();
    const payload = {
      name,
      description,
      under: under ? under.value : null,
      materialsegregation: materialsegregation ? materialsegregation.value : null,
      materialownership: materialownership ? materialownership.value : null,
      purchasewarehouse: purchasewarehouse ? purchasewarehouse.value : null,
      salewarehouse: salewarehouse ? salewarehouse.value : null,
    };
    setNameError1(null);
    setDescriptionError1(null);
    try {
      if (isEditing) {
        await axiosInstance.put(`inventory/itemgroup/${editId}/`, payload);
        setIsEditing(false);
      } else {
        await axiosInstance.post('inventory/itemgroup/create/', payload);
        setErrorMessage(null);
      }
      fetchCategories(currentPage);
      fetchSelectOptions();
      fetchpsaleWarehouseOptions();
      fetchpurWarehouseOptions();
      fetchOwnershipOptions();
      fetchSegregationOptions();
      resetForm();
    } catch (error) {
      console.error('Error:', error);
      if (error.response && error.response.data) {
        const nameError = error.response.data.name && Array.isArray(error.response.data.name) ? error.response.data.name[0] : null;
        const descriptionError = error.response.data.description && Array.isArray(error.response.data.description) ? error.response.data.description[0] : null;
        const existsError = error.response.data.error && Array.isArray(error.response.data.error) ? error.response.data.error[0] : null;
        console.log(nameError, 'Name Error');
        console.log(descriptionError, 'Description Error');
        if (nameError && descriptionError) {
          setNameError1(nameError);
          setDescriptionError1(descriptionError);
        } else if (nameError) {
          setNameError1(nameError)
        } else if (descriptionError) {
          setDescriptionError1(descriptionError);
        } else if (existsError) {
          setErrorMessage(existsError);
 
        }
      } else {
        setErrorMessage('An error occurred while submitting the form.');
      }
    }
  };

  const handleUpdateClick = (category) => {
    setEditId(category.id);
    setNameError2(null);
    setDescriptionError2(null);
    setName('');
    setDescription('');
    setIsEditing(false);
    setUnder(null);
    setNameError1(null);
    setClearRows(!clearRows);
    setSubmintbtn('Save')
    setDescriptionError1(null);
    setErrorMessage('');
    setNameError1(null);
    setDescriptionError1(null);
    setName(category.name);
    setDescription(category.description);
    setSubmintbtn('Update');
    console.log(category.id,'nbhgvcsfdnhvbjmb');
    setUnder({ value: category.under, label: category.under_name });
    setMaterialownership({value: category.materialownership, label: category.materialownership_name});
    setMaterialsegregation({value: category.materialsegregation, label: category.materialsegregation_name});
    setPurchasewarehouse({value: category.purchasewarehouse, label: category.purchasewarehouse_name});
    setSalewarehouse({value: category.salewarehouse, label: category.salewarehouse_name});
    setIsEditing(true);
    fetchSelectOptions(category.id);
    fetchpsaleWarehouseOptions(category.id);
    fetchpurWarehouseOptions(category.id);
    fetchOwnershipOptions(category.id);
    fetchSegregationOptions(category.id);
    setErrorMessage('');
  };

  const resetForm = () => {
    setNameError2(null);
    setNameError3(null);
    setDescriptionError2(null);
    setName('');
    setDescription('');
    setIsEditing(false);
    setUnder(null);
    setMaterialownership(null);
    setMaterialsegregation(null);
    setPurchasewarehouse(null);
    setSalewarehouse(null);
    setNameError1(null);
    setClearRows(!clearRows);
    setSubmintbtn('Save')
    setDescriptionError1(null);
    setEditId(null);
    setErrorMessage('');
  };

  const handleCheckboxChange = (selectedRows) => {
    setSelectedIds(selectedRows.selectedRows.map(row => row.id));
  };
  const handleDelete = async () => {
    try {
      const response = await axiosInstance.get('inventory/itemgroup/get/');
      const categories = response.data;

      const categoryMap = new Map(categories.map(cat => [cat.id, cat.name]));

      const selectedNames = selectedIds.map(id => categoryMap.get(id)).filter(name => name);

      const escapeHtml = (text) => {
        return text.replace(/[&<>"']/g, (match) => {
          const escape = {
            '&': '&amp;',
            '<': '&lt;',
            '>': '&gt;',
            '"': '&quot;',
            "'": '&#039;'
          };
          return escape[match];
        });
      };

      const namesText = selectedNames.map(name => `<strong>${escapeHtml(name)}</strong>`).join(', ');

      const result = await Swal.fire({
        title: 'Are you sure?',
        html: `You are about to delete the following Item Group: ${namesText}. You won't be able to revert this!`,
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Yes, delete it!'
      });

      if (result.isConfirmed) {
        await axiosInstance.delete('inventory/itemgroup/delete/', {
          data: { ids: selectedIds },
        });
        fetchCategories(currentPage);
        setSelectedIds([]);
        fetchSelectOptions();
        fetchpsaleWarehouseOptions();
        fetchpurWarehouseOptions();
        fetchOwnershipOptions();
        fetchSegregationOptions();
        resetForm();
        // Swal.fire('Deleted!', 'Your selected categories have been deleted.', 'success');
      }
    } catch (error) {
      console.error('Error:', error);
      Swal.fire('Error!', 'There was a problem fetching Item Group names or deleting the Item Group.', 'error');
    }
  };

  const handleDeleteCategory = async (id) => {
    // const result = await Swal.fire({
    //   title: 'Are you sure?',
    //   text: `You won't be able to revert this!`,
    //   icon: 'warning',
    //   showCancelButton: true,
    //   confirmButtonColor: '#3085d6',
    //   cancelButtonColor: '#d33',
    //   confirmButtonText: 'Yes, delete it!'
    // });

    // if (result.isConfirmed) 
    {
      try {
        await axiosInstance.delete(`inventory/itemgroup/${id}/`);
        fetchCategories(currentPage);
        // Swal.fire('Deleted!', 'Your category has been deleted.', 'success');
      } catch (error) {
        console.error('Error deleting category:', error);
        Swal.fire('Error!', 'There was a problem deleting the Item Group.', 'error');
      }

      setSelectedIds([]);
      fetchSelectOptions();
      fetchpsaleWarehouseOptions();
      fetchpurWarehouseOptions();
      fetchOwnershipOptions();
      fetchSegregationOptions();
      resetForm();
    }
  };
  const getHighlightedText = (text, highlight) => {
    // Escape special characters in the highlight string
    const escapedHighlight = highlight.replace(/[-/\\^$*+?.()|[\]{}]/g, '\\$&');
    const parts = text.split(new RegExp(`(${escapedHighlight})`, 'gi'));
    return (
      <span>
        {parts.map((part, index) =>
          part.toLowerCase() === highlight.toLowerCase() ? (
            <span key={index} style={{fontWeight:'bold' }}>{part}</span>
          ) : (
            part
          )
        )}
      </span>
    );
  };
  const validateName = (value) => {
    console.log(value,'kjhvckjsdnv');
    
   if (value.match('  ')) {
      setNameError1('Continuous spaces are not allowed ');
      return false;
    }  else if(value.length === 30){
      setNameError2(null);
    }else {
      setNameError1(null);
      return true;
    }
  };

  const handleNameChange = (e) => {
      const value = e.target.value;
      setName(value);
      validateName(value); 
  };
  const handleDescriptionChange = (e) => {
    const value = e.target.value;
    setDescription(value);
};
  const handleEnter = (event) => {
    if (event.target && nameError1 && (event.key === 11 || event.key === 13)) {
      event.target.focus();  
    } 
    else if (event.keyCode === 13 && event.target.form) {
      if (event.target.tagName === 'INPUT' || event.target.tagName === 'TEXTAREA') {
        event.target.value = event.target.value.trim();
      }
      if (dropdownOpen) {
        return; 
      }else if(dropdownSegOpen){
        return;
      }else if(dropdownOwnOpen){
        return;
      }else if(dropdownPurOpen){
        return;
      }else if(dropdownSalOpen){
        return;
      } else {
        const form = event.target.form;
        const index = Array.prototype.indexOf.call(form.elements, event.target);
        const nextElement = form.elements[index + 1];
        if (nextElement) {
          nextElement.focus();
        }
        event.preventDefault();
      }
    }
    const { value } = event.target;
    if (
      (event.key === ' ' && value.length === 0) || 
      (event.key === ' ' && value.endsWith(' ')) || 
      (event.key === ' ' && value.includes('  '))
    ) {
      event.preventDefault();
      return;
    }
  };
  
  const handleArrowNavigation = (event, buttonType) => {
    if (event.key === 'ArrowRight' && buttonType === 'save' && cancelButtonRef.current) {
      cancelButtonRef.current.focus();
    } else if (event.key === 'ArrowLeft' && buttonType === 'cancel' && submitButtonRef.current) {
      submitButtonRef.current.focus();
    }
  };
    const handleBlur = (e) => {
      setNameError2(null);
      setDescriptionError2(null);
      if (e.target.tagName === 'INPUT' || e.target.tagName === 'TEXTAREA') {
        e.target.value = e.target.value.trim();  // Ensures the value is trimmed properly
        
        if (nameError1) {
            e.target.focus();
        }else if (descriptionError1) {
          e.target.focus(); 
        }
      }
    };
    const handleDescriptionFocus = () => {
      // Perform name validation when switching to the description field
      if(isEditing){
        const otherCategories = categories.filter(category => category.id !== editId);
        console.log(otherCategories,'is editing');
        if (Array.isArray(otherCategories) && otherCategories.some((item) => item.name.replace(/\s+/g,'').toLowerCase() === name.replace(/\s+/g,'').toLowerCase())) {
          setShouldFocusName(true);
          setNameError3('Item Group with this name already exists.');
          setTimeout(() => {
            const nameInput = document.getElementById('nameInput');
            if (nameInput) {
              nameInput.focus(); // Set focus on the input with id 'nameInput'
            }
        }, 0);
        }
        else {
          setNameError3(null);
        }
        
      }else{
        if(Array.isArray(categories) && categories.some((item) => item.name.replace(/\s+/g,'').toLowerCase() === name.replace(/\s+/g,'').trim().toLowerCase())){
          setShouldFocusName(true);
          setNameError3('Item Group with this name already exists.');
          setTimeout(() => {
            const nameInput = document.getElementById('nameInput');
            if (nameInput) {
              nameInput.focus(); // Set focus on the input with id 'nameInput'
            }
          }, 0);
          return false;
        }else {
          setNameError3(null);
        }
  
      }
    };
  return (
    <Fragment>
      <Breadcrumbs mainTitle="Item Group" parent="Inventory" title="Item Group" />
      <Container fluid={true} className='Container1'>
        <div className='itemGrp'>
                <Card>
                
                <CardBody>
                <Form className="theme-form" onSubmit={handleSubmit}>
                  <div className='formdiv1'>
                  <div className='rowForm'>
                      <div className='formcol'>
                        <Row>
                          <FormGroup className='formgrp'>
                              <Label style={{fontWeight:"600"}} className="col-form-label" >Item Group</Label>
                              <Input style={{color:"black",borderColor: "#afafaf" ,height:"40px"}} onBlur={handleBlur} maxLength={nameLen} id="nameInput" className={classNames("form-control", "inputfoc", {"is-invalid": nameError1,})} type="text" onKeyDown={handleEnter}  value={name} onChange={handleNameChange} required/>
                              {nameError1 && (
                              <div className="alert alert-danger mt-2">
                                  {nameError1}
                              </div>
                              )}
                              {nameError2 && (
                                  <div className="alert alert-danger mt-2">
                                      {nameError2}
                                  </div>
                              )}
                              {nameError3 && (
                                  <div className="alert alert-danger mt-2">
                                      {nameError3}
                                  </div>
                              )}
                          </FormGroup>
                          
                          <FormGroup className='formgrp'>
                              <Label style={{fontWeight:"600"}} className="col-form-label">Under</Label>
                              <Select
                                  value={under}
                                  onBlur={handleBlur}
                                  onKeyDown={handleEnter}
                                  onFocus={handleDescriptionFocus}
                                  onMenuOpen={() => {
                                    setDropdownOpen(true);
                                  }}
                                  onMenuClose={() => {
                                    setDropdownOpen(false);
                                  }}
                                  styles={customStyles}
                                  onChange={(option) => setUnder(option)}
                                  options={selectOptions}
                                  filterOption={(option, inputValue) => 
                                    option.label.toLowerCase().startsWith(inputValue.toLowerCase())
                                  }
                                  formatOptionLabel={(option, { inputValue }) => 
                                    getHighlightedText(option.label, inputValue)
                                  }
                                  required
                              />   
                          </FormGroup>
                          <FormGroup className='formgrp'>
                              <Label style={{fontWeight:"600"}} className="col-form-label">Material Segregation</Label>
                              <Select
                                  value={materialsegregation}
                                  onBlur={handleBlur}
                                  onKeyDown={handleEnter}
                                  onMenuOpen={() => {
                                    setDropdownSegOpen(true);
                                  }}
                                  onMenuClose={() => {
                                    setDropdownSegOpen(false);
                                  }}
                                  onFocus={handleDescriptionFocus}
                                  styles={customStyles}
                                  onChange={(option) => setMaterialsegregation(option)}     
                                  options={segregationOptions}
                                  filterOption={(option, inputValue) => 
                                    option.label.toLowerCase().startsWith(inputValue.toLowerCase())
                                  }
                                  formatOptionLabel={(option, { inputValue }) => 
                                    getHighlightedText(option.label, inputValue)
                                  }
                                  required
                              />
                          </FormGroup>
                      </Row>
                      <Row>
                      <FormGroup className='formgrp1'>
                          <Label style={{fontWeight:"600"}} className="col-form-label">Material Ownership</Label>
                          <Select
                              value={materialownership}
                              onBlur={handleBlur}
                              onKeyDown={handleEnter}
                              onMenuOpen={() => {
                                setDropdownOwnOpen(true);
                              }}
                              onMenuClose={() => {
                                setDropdownOwnOpen(false);
                              }}
                              onFocus={handleDescriptionFocus}
                              styles={customStyles}
                              onChange={(option) => setMaterialownership(option)}
                              
                              options={ownershipOption}
                              filterOption={(option, inputValue) => 
                                option.label.toLowerCase().startsWith(inputValue.toLowerCase())
                              }
                              formatOptionLabel={(option, { inputValue }) => 
                                getHighlightedText(option.label, inputValue)
                              }
                              required
                          />
                      </FormGroup>
                      <FormGroup className='formgrp1'>
                          <Label style={{fontWeight:"600"}} className="col-form-label">Purchase Warehouse</Label>
                          <Select
                              value={purchasewarehouse}
                              styles={customStyles}
                              onChange={(option) => setPurchasewarehouse(option)}
                              onMenuOpen={() => {
                                setDropdownPurOpen(true);
                              }}
                              onMenuClose={() => {
                                setDropdownPurOpen(false);
                              }}
                              onBlur={handleBlur}
                              onKeyDown={handleEnter}
                              onFocus={handleDescriptionFocus}
                              options={purwarehouseOptions}
                              filterOption={(option, inputValue) => 
                                option.label.toLowerCase().startsWith(inputValue.toLowerCase())
                              }
                              formatOptionLabel={(option, { inputValue }) => 
                                getHighlightedText(option.label, inputValue)
                              }
                              required
                          />
                      </FormGroup>
                      <FormGroup className='formgrp1'>
                          <Label style={{fontWeight:"600"}} className="col-form-label">Sale Warehouse</Label>
                          <Select
                              value={salewarehouse}
                              styles={customStyles}
                              onChange={(option) => setSalewarehouse(option)}
                              onMenuOpen={() => {
                                setDropdownSalOpen(true);
                              }}
                              onMenuClose={() => {
                                setDropdownSalOpen(false);
                              }}
                              onBlur={handleBlur}
                              onKeyDown={handleEnter}
                              onFocus={handleDescriptionFocus}
                              options={salewarehouseOptions}
                              filterOption={(option, inputValue) => 
                                option.label.toLowerCase().startsWith(inputValue.toLowerCase())
                              }
                              formatOptionLabel={(option, { inputValue }) => 
                                getHighlightedText(option.label, inputValue)
                              }
                              required
                          />
                      </FormGroup>
                      </Row>
                      </div>
                    
                    
                    </div>
                    <div className=''>
                          <FormGroup>
                              <Label style={{fontWeight:"600"}} className="col-form-label">Description</Label>
                              <Input onFocus={handleDescriptionFocus} maxLength={desLen} onBlur={handleBlur} onKeyDown={handleEnter} id='desInput' style={{color:"black",borderColor: "#afafaf"}} className={classNames("form-control", "inputfoc1", { "is-invalid": descriptionError1 }, "custom-width", "custom-height")} type="textarea" placeholder="" value={description} onChange={handleDescriptionChange}/>
                          </FormGroup>
                          {descriptionError1 && (
                          <div className="alert alert-danger">
                              {descriptionError1}
                          </div>
                          )}
                          {descriptionError2 && (
                              <div className="alert alert-danger">
                                  {descriptionError2}
                              </div>
                          )}
                          {errorMessage && (
                              <div className="alert alert-danger">
                                  {errorMessage}
                              </div>
                          )}
                        </div>
                  </div>
                    <div >
                        <div style={{gap:"10px",display:"flex", justifyContent:"right", marginTop:"5px", marginBottom:"-20px"}}>
                        <button ref={submitButtonRef} onKeyDown={(event) => handleArrowNavigation(event, 'save')} className='btn2' color='primary' type='submit' disabled={nameError1 || nameError2 || nameError3 || descriptionError1 || descriptionError2 || (name === '')}>
                              {submitbtn}
                          </button>
                          <button className='btn1' onClick={resetForm} ref={cancelButtonRef} onKeyDown={(event) => handleArrowNavigation(event, 'cancel')}>
                              Cancel
                          </button>
                        </div>
                      </div>
                </Form>
                </CardBody>
                </Card>
          <Card>
            <div style={{ display: "flex" }}>
              <div className='headercl'>
              <HeaderCard title="Item Group List" />
              </div>
              <div className='selectdel' style={{ display: "flex", alignItems: "center", justifyContent: "flex-end", width: "40%" }}>
              <Button title='Delete' color="danger" className='btncon' onClick={handleDelete} disabled={selectedIds.length === 0}><IoTrash className="vendortrash1" />Delete</Button>
              </div>
            </div>
            <CardBody>
              <div className="table-responsive">
                <InputGroup className="mb-3">
                  <Input
                      type="text"
                      placeholder="Search"
                      style={{color:"black",borderColor: "#afafaf" }}
                      value={searchTerm}
                      onChange={(e) => setSearchTerm(e.target.value)}
                      className="form-control input-no-animation inputfoc"
                  />
                  <InputGroupText >
                      <FaSearch />
                  </InputGroupText>
                </InputGroup>
                <DataTable
                  columns={columns}
                  data={paginatedData}
                  selectableRows
                  selectableRowsSelected={selectedIds}
                  onSelectedRowsChange={handleCheckboxChange}
                  clearSelectedRows={clearRows}
                  pagination
                  paginationServer
                  customStyles={customStylesTable}
                  striped={false}
                  paginationTotalRows={categories.length}
                  paginationPerPage={rowsPerPage}
                  progressPending={loading}
                  autoWidth={true}
                  paginationComponentOptions={{
                    noRowsPerPage: true, // Disable option to change rows per page
                  }}
                  onChangePage={handlePageChange} // Handle pagination
                  highlightOnHover
                />
              </div>
            </CardBody>
          </Card>
        </div>
      </Container>
    </Fragment>
  );
}

export default ItemGroup;
