import React, { Fragment, useEffect, useState, useRef } from 'react';
import { Container, Row, Col, Card, CardBody, Form, FormGroup, Label, Input, Table, CardHeader, Button,InputGroup ,InputGroupText} from 'reactstrap';
import Select from 'react-select';
import { Breadcrumbs } from '../../../../AbstractElements';
import HeaderCard from '../../../Common/Component/HeaderCard';
import Swal from 'sweetalert2';
import { IoTrash } from "react-icons/io5";
import DataTable from 'react-data-table-component';
import '../forms.css';
import axiosInstance from '../../../../Auth/AxiosInstance';
import Highlighter from 'react-highlight-words';
import { FaRegEdit,FaSearch } from "react-icons/fa";
import classNames from "classnames";

function Currency() {
  const [name, setName] = useState('');
  const [formal_name, setFormalName] = useState('');
  const [decimal_places, setDecimalplaces] = useState('');
  const [symbol, setSymbols] = useState('');
  const [decimal_portion_name, setDecimalportion] = useState('');
  const [currency_location,setCurrency_location ] = useState(null)
 
  
  const [categoryName, setCategoryName] = useState('');
  const [description, setDescription] = useState('');
  const [under, setUnder] = useState(null);
  const [categories, setCategories] = useState([]);
  const [clearRows, setClearRows] = useState(false);
  const [selectedIds, setSelectedIds] = useState([]);
  const [isEditing, setIsEditing] = useState(false);
  const [editId, setEditId] = useState(null);
  const [errorMessage, setErrorMessage] = useState(null);
  const [selectOptions, setSelectOptions] = useState([{ value: '1', label: '[] Primary' }]);
  const [nameError1 , setNameError1] = useState(null);
  const [descriptionError1, setDescriptionError1] = useState(null);
  const [nameError2 , setNameError2] = useState(null);
  const [nameError3 , setNameError3] = useState(null);
  const [descriptionError2, setDescriptionError2] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [loading, setLoading] = useState(true);
  const [searchTerm, setSearchTerm] = useState('');
  const [filteredCategories, setFilteredCategories] = useState([]);
  const [submitbtn, setSubmintbtn] =useState('Save');
  const [opt1 , setOpt1] = useState([]);
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const nameLen = 31;
  const desLen = 51;
  const [shouldFocusName, setShouldFocusName] = useState(false);
  const cancelButtonRef = useRef(null);
  const submitButtonRef = useRef(null);

  const rowsPerPage = 10;
  const customStylesTable = {
    header: {
      style: {
        minHeight: '56px',
        backgroundColor: '#f0f0f0',
        color: '#333',
      },
    },
    headRow: {
      style: {
        backgroundColor: '#f0f0f0',
      },
    },
    headCells: {
      style: {
        fontSize: '14px',
        fontWeight: 'bold',
        textAlign: 'left',
        color: '#000',
      },
    },
    rows: {
      style: {
        minHeight: '48px',
        borderBottom: '1px solid #ddd',
      },
    },
  };
  
  const findthelocation = (location) => {
    return location == 1 ? "Local currency" : "Foreign currency"
  }
  const customStyles = {
    control: (provided, state) => ({
        ...provided,
        borderColor: state.isFocused ? 'blue' : '#afafaf', 
        boxShadow: state.isFocused ? '0 0 0 1px blue' : 'none',
        '&:hover': {
            borderColor: state.isFocused ? 'blue' : 'black',
        },
    }),
};
  const paginatedData = Array.isArray(categories) ? categories.slice((currentPage - 1) * rowsPerPage, currentPage * rowsPerPage) : [];
  const columns = [
    {
      name: 'SN',
      selector: (row, index) => index + 1 + (currentPage - 1) * rowsPerPage,
      // width:'10%',
      width:"70px",
      cell:(row, index) =>(
        <div style={{textAlign: 'left !important',display:"flex", justifyContent:"flex-start !important" ,paddingLeft:"4px"}}>{index + 1 + (currentPage - 1) * rowsPerPage}</div>
      )
    },
    {
      name: 'Category',
      // width:"20%",
      selector: row => row.name,
      cell: row => (
        <div style={{textAlign: 'left !important',display:"flex", justifyContent:"flex-start !important" ,paddingLeft:"4px"}}>
          <Highlighter
            highlightClassName="highlighted-text"
            searchWords={[searchTerm]}
            autoEscape={true}
            textToHighlight={row.name}
        />
        </div>
      ),
    },
    {
      name:'',
      width:'5px',
    },
    {
      name:(<div>Formal name</div>) ,
      // width:"20%",
      selector: row => row.formal_name,
      cell: row => (
        <div style={{textAlign: 'left !important',display:"flex", justifyContent:"flex-start !important" ,paddingLeft:"12px"}}>
          <Highlighter
            highlightClassName="highlighted-text"
            searchWords={[searchTerm]}
            autoEscape={true}
            textToHighlight={row.formal_name}
        />
        </div>
      ),
    },
    {
      name: 'Symbol',
      // width:"23%",
      
      selector: row => row.symbol,
      cell: row => (
        <div style={{textAlign: 'left !important',display:"flex", justifyContent:"flex-start !important" ,paddingLeft:"12px"}}>
          <Highlighter
            highlightClassName="highlighted-text"
            searchWords={[searchTerm]}
            autoEscape={true}
            textToHighlight={row.symbol}
        />
        </div>
      ),
      
    },
    { 
      name: (<div>Decimal places</div>),
      // width:"23%",
      selector: row => row.decimal_places,
      cell: row => (
        <div style={{textAlign: 'left !important',display:"flex", justifyContent:"flex-start !important" ,paddingLeft:"12px"}}>
          <Highlighter
            highlightClassName="highlighted-text"
            searchWords={[searchTerm]}
            autoEscape={true}
            textToHighlight={String(row.decimal_places)}
        />
        </div>
      ),
      
    },
    { 
      name: (<div>Decimal portion</div>),
      // width:"23%",
      selector: row => row.decimal_portion_name,
      cell: row => (
        <div style={{textAlign: 'left !important',display:"flex", justifyContent:"flex-start !important" ,paddingLeft:"12px"}}>
          <Highlighter
            highlightClassName="highlighted-text"
            searchWords={[searchTerm]}
            autoEscape={true}
            textToHighlight={String(row.decimal_portion_name)}
        />
        </div>
      ),
      
    },
    { 
      name: 'Currency',
      // width:"23%",
      selector: row => row.currency_location,
      cell: row => (
        <div style={{textAlign: 'left !important',display:"flex", justifyContent:"flex-start !important" ,paddingLeft:"12px"}}>
          <Highlighter
            highlightClassName="highlighted-text"
            searchWords={[searchTerm]}
            autoEscape={true}
            textToHighlight = {findthelocation(row.currency_location)}
        />
        </div>
      ),
      
    },
    {
      name: 'Action',
      center: true,
      // width:'15%',
      cell: row => (
        <div className="buttonven">
          <button
            className="vendorupdate"
            color='primary'
            title='Edit'
            onClick={() => handleUpdateClick(row)}
          >
            <FaRegEdit className="vendortrash"/>
          </button>
          <button title='Delete' className="vendordele" onClick={() => handleDeleteCategory(row.id)}>
            <IoTrash className="vendortrash" />
          </button>
        </div>
      ),
    },
  ];


  useEffect(() => {
    if (searchTerm.trim() === '') {
      setFilteredCategories(categories || []);
    } else {
      const safeCategories = Array.isArray(categories) ? categories : [];
      const filteredData = safeCategories.filter(item =>
        item.name && item.name.toLowerCase().includes(searchTerm.toLowerCase())
      );
      setFilteredCategories(filteredData);
    }
  }, [searchTerm, currentPage, categories]);

  useEffect(() => {
    fetchCategories(currentPage);
    fetchSelectOptions();
  }, [currentPage,searchTerm]); 

  const fetchCategories = async (page) => {
    setLoading(true);
    try {
      let response;
      if (searchTerm) {
          response = await axiosInstance.get(`/generalledger/generalcurrency/search/?name=${searchTerm}`);
      } else {
          response = await axiosInstance.get(`generalledger/generalcurrency/get/`);
      }
      setCategories(response.data);
      setFilteredCategories(response.data); 
    } catch (error) {
      if (error.response && error.response.status === 404 && page > 1) {
        handlePageChange(page - 1);
      } else {
        console.error("Error fetching categories:", error);
      }
    }finally {
      setLoading(false);
    }
  };
  const handlePageChange = (page) => {
    setCurrentPage(page);
    fetchCategories(page);
  };
  const fetchSelectOptions = async (editId = null) => {
    try {
      const response = await axiosInstance.get('generalledger/generalcurrency/get/');
      console.log(editId,'snckdsvv');
      const opt = response.data.map(category => ({
        value: category.id,
        label: category.name
      }));
      setOpt1(opt);
      console.log(opt,'options');
      const filteredOptions = response.data
      .filter(category => category.id !== editId)
      .map(category => ({
        value: category.id,
        label: category.name
      }));
      setSelectOptions([{ value: '1', label: '[] Primary' }, ...filteredOptions]);
    } catch (error) {
      console.error('Error fetching select options:', error);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const payload = {
      name,
      formal_name,
      symbol,
      decimal_places,
      decimal_portion_name,
      currency_location
  

      
    };
    setNameError1(null);
    setDescriptionError1(null);
    try {
      if (isEditing) {
        await axiosInstance.put(`generalledger/generalcurrency/${editId}/`, payload);
        setIsEditing(false);
      } else {
        await axiosInstance.post('generalledger/generalcurrency/create/', payload);
        setErrorMessage(null);
      }
      fetchCategories(currentPage);
      fetchSelectOptions();
      resetForm();
      // Swal.fire({
      //   title: 'Success!',
      //   text: 'Currency Category has been saved successfully.',
      //   icon: 'success',
      //   confirmButtonText: 'OK'
      // });
    } catch (error) {
      console.error('Error:', error);
      if (error.response && error.response.data) {
        const nameError = error.response.data.name && Array.isArray(error.response.data.name) ? error.response.data.name[0] : null;
        const descriptionError = error.response.data.description && Array.isArray(error.response.data.description) ? error.response.data.description[0] : null;
        const existsError = error.response.data.error && Array.isArray(error.response.data.error) ? error.response.data.error[0] : null;
        console.log(nameError, 'Name Error');
        console.log(descriptionError, 'Description Error');
        if (nameError && descriptionError) {
        } else if (nameError) {
          setNameError1(nameError)
        } else if (descriptionError) {
          setDescriptionError1(descriptionError);
        } else if (existsError) {
          setErrorMessage(existsError);
 
        }
      } else {
        setErrorMessage('An error occurred while submitting the form.');
      }
    }
  };

  const handleUpdateClick = (category) => {
    setNameError1(null);
    setNameError3(null);
    setEditId(category.id);
    setNameError2(null);
    setDescriptionError2(null);
    setName('');
    setDescription('');
    setIsEditing(false);
    setUnder(null);
    setNameError1(null);
    setClearRows(!clearRows);
    setSubmintbtn('Save')
    setDescriptionError1(null);
    setErrorMessage('');
    setNameError1(null);
    setDescriptionError1(null);
    
    setName(category.name);
    setFormalName(category.formal_name)
    setDecimalplaces(category.decimal_places)
    setSymbols(category.symbol)
    setDecimalportion(category.decimal_portion_name)
    setCurrency_location(category.currency_location)
    // setDescription(check());
    setSubmintbtn('Update');
    console.log(category.id,'nbhgvcsfdnhvbjmb');
    setUnder({ value: category.under, label: category.under_name });
    setIsEditing(true);
    fetchSelectOptions(category.id);
    setErrorMessage('');
  };

  const resetForm = () => {
 
    setNameError2(null);
    setNameError3(null);
    setDescriptionError2(null);
    setName('');
    setDescription('');
    setIsEditing(false);
    setUnder(null);
    setNameError1(null);
    setClearRows(!clearRows);
    setSubmintbtn('Save')
    setDescriptionError1(null);
    setEditId(null);
    setFormalName('')
    setDecimalplaces('')
    setSymbols('')
    setDecimalportion('')
    setCurrency_location(null)
    setErrorMessage('');
  };

  const handleCheckboxChange = (selectedRows) => {
    setSelectedIds(selectedRows.selectedRows.map(row => row.id));
  };
  const handleDelete = async () => {
    try {
      const response = await axiosInstance.get('generalledger/generalcurrency/get/');
      const categories = response.data;

      const categoryMap = new Map(categories.map(cat => [cat.id, cat.name]));

      const selectedNames = selectedIds.map(id => categoryMap.get(id)).filter(name => name);

      const escapeHtml = (text) => {
        return text.replace(/[&<>"']/g, (match) => {
          const escape = {
            '&': '&amp;',
            '<': '&lt;',
            '>': '&gt;',
            '"': '&quot;',
            "'": '&#039;'
          };
          return escape[match];
        });
      };

      const namesText = selectedNames.map(name => `<strong>${escapeHtml(name)}</strong>`).join(', ');

      const result = await Swal.fire({
        title: 'Are you sure?',
        html: `You are about to delete the following categories: ${namesText}. You won't be able to revert this!`,
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Yes, delete it!'
      });

      if (result.isConfirmed) {
        await axiosInstance.delete('generalledger/generalcurrency/delete/', {
          data: { ids: selectedIds },
        });
        fetchCategories(currentPage);
        setSelectedIds([]);
        fetchSelectOptions();
        resetForm();
      }
    } catch (error) {
      console.error('Error:', error.response.data);
      const errorMessage = JSON.stringify(error.response.data.detail, null, 2);
      Swal.fire('Error!',errorMessage , 'error');
    }
  };

  const handleDeleteCategory = async (id) => {
    const result = await Swal.fire({
      title: 'Are you sure?',
      text: `You won't be able to revert this!`,
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes, delete it!'
    });

    if (result.isConfirmed) {
      try {
        await axiosInstance.delete(`generalledger/generalcurrency/${id}/`);
        fetchCategories(currentPage);
      } catch (error) {
        console.error('Error deleting category:', error.response.data);
        const errorMessage = JSON.stringify(error.response.data.detail, null, 2);
        Swal.fire('Error!', errorMessage, 'error');
      }

      setSelectedIds([]);
      fetchSelectOptions();
      resetForm();
    }
  };

//   const getHighlightedText = (text, highlight) => {
//     const escapedHighlight = highlight.replace(/[-/\\^$*+?.()|[\]{}]/g, '\\$&');
//     const parts = text.split(new RegExp(`(${escapedHighlight})`, 'gi'));
//     return (
//       <span>
//         {parts.map((part, index) =>
//           part.toLowerCase() === highlight.toLowerCase() ? (
//             <span key={index} style={{fontWeight:'bold' }}>{part}</span>
//           ) : (
//             part
//           )
//         )}
//       </span>
//     );
//   };
  const validateName = (value) => {
    console.log(value,'kjhvckjsdnv');
    
   if (value.match('  ')) {
      setNameError1('Continuous spaces are not allowed ');
      return false;
    }  else if(value.length === 30){
      setNameError2(null);
    }else {
      setNameError1(null);
      return true;
    }
  };

  const handleNameChange = (e) => {
      let value = e.target.value;
      value = value.replace(/\s+/g,' ').trimStart();
      setName(value);
      validateName(value); 
  };

  const handleNameChanges1 = (e) => {
    let value = e.target.value;
    value = value.replace(/\s+/g,' ').trimStart();
    setFormalName(value);
    validateName(value); 
  };
const handleNameChanges2 = (e) => {
        let value = e.target.value;
        value = value.replace(/\s+/g,' ').trimStart();
        setSymbols(value);
        validateName(value); 
        
};

const handleNameChanges3 = (e) => {
    let value = e.target.value;
    value = value.replace(/\s+/g,' ').trimStart();
    setDecimalplaces(value);
    validateName(value); 
}
const handleNameChanges4 = (e) => {
    let value = e.target.value;
    value = value.replace(/\s+/g,' ').trimStart();
    setDecimalportion(value);
    validateName(value); 
}


const settingdata = (e) =>{
  if (e.target.id === 'radioinline1'){
    setCurrency_location(1)

  }
  if(e.target.id === 'radioinline2'){
    setCurrency_location(2)

  }
}

const handleEnter = (event) => {
  if (event.target && nameError1 && (event.key === 11 || event.key === 13)) {
    event.target.focus();  
  } 
  else if (event.keyCode === 13 && event.target.form) {
    if (event.target.tagName === 'INPUT' || event.target.tagName === 'TEXTAREA') {
      event.target.value = event.target.value.trim();
    }
    if (dropdownOpen) {
      return; 
    } else {
      const form = event.target.form;
      const index = Array.prototype.indexOf.call(form.elements, event.target);
      const nextElement = form.elements[index + 1];
      if (nextElement) {
        nextElement.focus();
      
        if (nextElement.type == "radio" && nextElement.id == "radioinline1" 
        ){
      
        document.getElementById("radioinline1").checked = true;
        
          
        }
        else if(nextElement.type == "radio" && nextElement.id == "radioinline2" ){
          document.getElementById("radioinline2").checked = true;

        }
        
      
      }
      event.preventDefault();
    }
  }
  const { value } = event.target;
  if (
    (event.key === ' ' && value.length === 0) || 
    (event.key === ' ' && value.endsWith(' ')) || 
    (event.key === ' ' && value.includes('  '))
  ) {
    event.preventDefault();
    return;
  }
};

const handleArrowNavigation = (event, buttonType) => {
  if (event.key === 'ArrowRight' && buttonType === 'save' && cancelButtonRef.current) {
    cancelButtonRef.current.focus();
  } else if (event.key === 'ArrowLeft' && buttonType === 'cancel' && submitButtonRef.current) {
    submitButtonRef.current.focus();
  }
};
  const handleBlur = (e) => {
    setNameError2(null);
    setDescriptionError2(null);
    if (e.target.tagName === 'INPUT' || e.target.tagName === 'TEXTAREA') {
      e.target.value = e.target.value.trim();  // Ensures the value is trimmed properly
      
      if (nameError1) {
          e.target.focus();
      }else if (descriptionError1) {
        e.target.focus(); 
      }
    }
  };
  const handleDescriptionFocus = () => {
    // Perform name validation when switching to the description field
    if(isEditing){
      const otherCategories = categories.filter(category => category.id !== editId);
      console.log(otherCategories,'is editing');
      if (Array.isArray(otherCategories) && otherCategories.some((item) => item.name.replace(/\s+/g,'').toLowerCase() === name.replace(/\s+/g,'').toLowerCase())) {
        setShouldFocusName(true);
        setNameError3('Currency code with this name already exists.');
        setTimeout(() => {
          const nameInput = document.getElementById('nameInput');
          if (nameInput) {
            nameInput.focus(); // Set focus on the input with id 'nameInput'
          }
      }, 0);
      }
      else {
        setNameError3(null);
      }
      
    }else{
      if(Array.isArray(categories) && categories.some((item) => item.name.replace(/\s+/g,'').toLowerCase() === name.replace(/\s+/g,'').trim().toLowerCase())){
        setShouldFocusName(true);
        setNameError3('Currency code with this name already exists.');
        setTimeout(() => {
          const nameInput = document.getElementById('nameInput');
          if (nameInput) {
            nameInput.focus(); // Set focus on the input with id 'nameInput'
          }
        }, 0);
        return false;
      }else {
        setNameError3(null);
      }

    }
  };
  return (
    <Fragment>
      <Breadcrumbs mainTitle="Currency" parent="Procument" title="Currency Category" />
      <Container fluid={true} className='Container1'>
        <div className='vendordiv1'>
        <Col sm="12" xl="6" className='vendorcol1'>
          <Card>
            <div style={{ display: "flex" }}>
              <div className='headercl'>
              <HeaderCard title="Currency Category List" />
              </div>
              <div className='selectdel' style={{ display: "flex", alignItems: "center", justifyContent: "flex-end", width: "40%" }}>
              <Button title='Delete' color="danger" className='btncon' onClick={handleDelete} disabled={selectedIds.length === 0}><IoTrash className="vendortrash1" />Delete</Button>
              </div>
            </div>
            <CardBody>
              <div className="table-responsive">
                <InputGroup className="mb-3">
                  <Input
                      type="text"
                      placeholder="Search"
                      style={{color:"black",borderColor: "#afafaf" }}
                      value={searchTerm}
                      onChange={(e) => setSearchTerm(e.target.value)}
                      className="form-control input-no-animation inputfoc"
                  />
                  <InputGroupText >
                      <FaSearch />
                  </InputGroupText>
                </InputGroup>
                <DataTable
                  columns={columns}
                  data={paginatedData}
                  selectableRows
                  selectableRowsSelected={selectedIds}
                  onSelectedRowsChange={handleCheckboxChange}
                  clearSelectedRows={clearRows}
                  pagination
                  paginationServer
                  reorderable={true}
                  customStyles={customStylesTable}
                  striped={false}
                  paginationTotalRows={categories.length}
                  paginationPerPage={rowsPerPage}
                  progressPending={loading}
                  autoWidth={true}
                  paginationComponentOptions={{
                    noRowsPerPage: true,
                  }}
                  onChangePage={handlePageChange}
                  highlightOnHover
                />
              </div>
            </CardBody>
          </Card>
        </Col>
        <Col sm="12" xl="6" className='vendorcol2'>
                <Card>
                <HeaderCard title={"Currency Category"} />
                <CardBody>
                <Form className="theme-form" id='form' onSubmit={handleSubmit}>
                    <FormGroup className='custom-margin'>
                        <Label style={{fontWeight:"600"}} className="col-form-label pt-0" >Currency code</Label>
                        <Input autoComplete="off"  style={{color:"black",borderColor: "#afafaf",  height:"40px" }} onBlur={handleBlur} maxLength="10" id="nameInput" className={classNames("form-control", "inputfoc", {"is-invalid": nameError1,})} type="text" onKeyDown={handleEnter}  value={name} onChange={handleNameChange} required/>
                    </FormGroup>
                    {nameError1 && (
                        <div className="alert alert-danger">
                            {nameError1}
                        </div>
                    )}
                    {nameError2 && (
                        <div className="alert alert-danger">
                            {nameError2}
                        </div>
                    )}
                    {nameError3 && (
                        <div className="alert alert-danger">
                            {nameError3}
                        </div>
                    )}
                    <FormGroup className='custom-margin'>
                        <Label style={{fontWeight:"600"}} className="col-form-label pt-0" >Formal Name</Label>
                        <Input autoComplete="off"  style={{color:"black",borderColor: "#afafaf",  height:"40px" }} onBlur={handleBlur}  onFocus={handleDescriptionFocus} maxLength="30" id="nameInput" className={classNames("form-control", "inputfoc", {"is-invalid": nameError1,})} type="text" onKeyDown={handleEnter}  value={formal_name} onChange={handleNameChanges1} required/>
                    </FormGroup>
                    {/* {nameError1 && (
                        <div className="alert alert-danger">
                            {nameError1}
                        </div>
                    )}
                    {nameError2 && (
                        <div className="alert alert-danger">
                            {nameError2}
                        </div>
                    )}
                    {nameError3 && (
                        <div className="alert alert-danger">
                            {nameError3}
                        </div>
                    )} */}
                    <FormGroup className='custom-margin'>
                        <Label style={{fontWeight:"600"}} className="col-form-label pt-0" >Symbol</Label>
                        <Input autoComplete="off"  style={{color:"black",borderColor: "#afafaf",  height:"40px" }} onBlur={handleBlur} maxLength="3" id="nameInput" className={classNames("form-control", "inputfoc", {"is-invalid": nameError1,})} type="text" onKeyDown={handleEnter}  value={symbol} onChange={handleNameChanges2} required/>
                    </FormGroup>
                    {/* {nameError1 && (
                        <div className="alert alert-danger">
                            {nameError1}
                        </div>
                    )}
                    {nameError2 && (
                        <div className="alert alert-danger">
                            {nameError2}
                        </div>
                    )}
                    {nameError3 && (
                        <div className="alert alert-danger">
                            {nameError3}
                        </div>
                    )} */}
                    <FormGroup className='custom-margin'>
                        <Label style={{fontWeight:"600"}} className="col-form-label pt-0" >Number of decimal places</Label>
                        <Input autoComplete="off"  style={{color:"black",borderColor: "#afafaf",  height:"40px" }} onBlur={handleBlur} maxLength="30" id="nameInput" className={classNames("form-control", "inputfoc", {"is-invalid": nameError1,})} type="number" onKeyDown={handleEnter}  value={decimal_places} onChange={handleNameChanges3} required/>
                    </FormGroup>
                    {/* {nameError1 && (
                        <div className="alert alert-danger">
                            {nameError1}
                        </div>
                    )}
                    {nameError2 && (
                        <div className="alert alert-danger">
                            {nameError2}
                        </div>
                    )}
                    {nameError3 && (
                        <div className="alert alert-danger">
                            {nameError3}
                        </div>
                    )} */}
                    <FormGroup className='custom-margin'>
                        <Label style={{fontWeight:"600"}} className="col-form-label pt-0" >Name of decimal portion</Label>
                        <Input autoComplete="off"  style={{color:"black",borderColor: "#afafaf",  height:"40px" }} onBlur={handleBlur} maxLength="15" id="nameInput" className={classNames("form-control", "inputfoc", {"is-invalid": nameError1,})} type="text" onKeyDown={handleEnter}  value={decimal_portion_name} onChange={handleNameChanges4} required/>
                    </FormGroup>
                    {/* {nameError1 && (
                        <div className="alert alert-danger">
                            {nameError1}
                        </div>
                    )}
                    {nameError2 && (
                        <div className="alert alert-danger">
                            {nameError2}
                        </div>
                    )}
                    {nameError3 && (
                        <div className="alert alert-danger">
                            {nameError3}
                        </div>
                    )} */}
                    <Col>
                <div className='m-t-15 m-checkbox-inline custom-radio-ml'>
                  <div className='radio radio-primary'>
                    <Input id='radioinline1' type='radio' onChange={settingdata} checked={currency_location === 1} name='radio1'onKeyDown={handleEnter} />
                    <Label style={{fontWeight:"600"}} className="col-form-label pt-0"  for='radioinline1'  >
                    Local Currency
                      
                    </Label>
                  </div>
                  <div className='radio radio-primary'>
                    <Input id='radioinline2' type='radio' name='radio1' checked={currency_location === 2}  onChange={settingdata} onKeyDown={handleEnter} />
                    <Label style={{fontWeight:"600"}} className="col-form-label pt-0"  for='radioinline2'  >
                    Foreign Currency
                    
                    </Label>
                  </div>
                 
                </div>
              </Col>
                    <div className="mt-3" style={{gap:"10px",display:"flex", justifyContent:"flex-end"}}>
                        <button className='btn2' color='primary' ref={submitButtonRef} style={{width:"170px"}} onKeyDown={(event) => handleArrowNavigation(event, 'save')} type='submit' disabled={nameError1 || nameError2 || nameError3 || descriptionError1 || descriptionError2 || (name === '')}>
                            {submitbtn}
                        </button>
                        <button className='btn1' ref={cancelButtonRef} style={{width:"170px"}}  onKeyDown={(event) => handleArrowNavigation(event, 'cancel')} onClick={resetForm}>
                            Cancel
                        </button>
                        
                    </div>
                </Form>
                </CardBody>
                </Card>
            </Col>
        </div>
      </Container>
    </Fragment>
  );
}

export default Currency;
