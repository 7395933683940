import React, { Fragment } from 'react';
import { Container, Row, Col, Card, CardBody,Button, Form, FormGroup, Label, Input,InputGroup, InputGroupText } from 'reactstrap';
import { useState, useEffect,useRef } from 'react';
import { Breadcrumbs, P } from '../../../../../AbstractElements';
import HeaderCard from '../../../../Common/Component/HeaderCard';
import axiosInstance from '../../../../../Auth/AxiosInstance';
import '../../forms.css';
import { FaRegEdit, FaSearch } from "react-icons/fa";
import { IoTrash } from "react-icons/io5";
import DataTable from 'react-data-table-component';
import Highlighter from 'react-highlight-words';
import classNames from "classnames";
import Swal from 'sweetalert2';


function MaterialSegregation(){
    const [paymentBase, setPaymentBase] = useState([]);
    const [searchTerm, setSearchTerm] = useState('');
    const [selectedIds, setSelectedIds] = useState([]); 
    const [currentPage, setCurrentPage] = useState(1);
    const [name, setName] = useState('');
    const [notes, setNotes] = useState('');
    const [loading, setLoading] = useState(true);
    const [isEditing, setIsEditing] = useState(false);
    const [nameError3 , setNameError3] = useState(null);
    const [descriptionError1, setDescriptionError1] = useState(null);
    const [clearRows, setClearRows] = useState(false);
    const [editId, setEditId] = useState(null);
    const [errorMessage, setErrorMessage] = useState(null);
    const [nameError1, setNameError1] = useState(null);
    const [notesError1, setNotesError1] = useState(null);
    const [nameError2 , setNameError2] = useState(null);
    const [descriptionError2, setDescriptionError2] = useState(null);
    const [filteredPaymentBase, setFilteredPaymentBase] = useState([]);
    const [shouldFocusName, setShouldFocusName] = useState(false);
    const cancelButtonRef = useRef(null);
    const submitButtonRef = useRef(null);
  
  
    
    const [submitbtn, setSubmintbtn] =useState('Save');
  
    const rowsPerPage = 10;
 
    const paginatedData = Array.isArray(paymentBase) ? paymentBase.slice((currentPage - 1) * rowsPerPage, currentPage * rowsPerPage) : [];
    console.log(filteredPaymentBase)
    const columns = [
        {
          name: 'SN',
          width:'70px',
          cell: (row, index) => (
            <div style={{textAlign: 'left !important',display:"flex", justifyContent:"flex-start !important" ,paddingLeft:"2px"}}>
              { index + 1 + (currentPage - 1) * rowsPerPage}
            
            </div>
          ),
         
          center: true,
        },
        {
          name:  (
            <div >Material Segregation</div>
          ),
          selector: row => row.name,
          width:"220px",
          sortable:true,
          center: false,
          cell: row => (
            <div style={{textAlign: 'left !important',display:"flex", justifyContent:"flex-start !important" ,paddingLeft:"4px"}}>
              <Highlighter
                highlightClassName="highlighted-text"
                searchWords={[searchTerm]}
                autoEscape={true}
                textToHighlight={row.name}
              />
            </div>
          ),
        },
        {
          name: '',
          selector: row => '',
          width:'5px',
          center: true,
        },
        {
          name: 'Notes',
          // width:'40%',
          selector: row => row.notes,  
          center: true,
          cell: row => (
            <div style={{textAlign: 'left !important',display:"flex", justifyContent:"flex-start !important"  ,paddingLeft:"12px" }}>
              {row.notes}
            </div>
          ),
        },
        {
          name: 'Action',
          center: true,
          width:'100px',
          cell: row => (
            <div className="buttonven"style={{paddingLeft:"-20px" }}>
              <button
                className="vendorupdate"
                color='primary'
                title='Edit'
                onClick={() => handleUpdateClick(row)}
              >
                <FaRegEdit className="vendortrash"/>
              </button>
              <button title='Delete' className="vendordele" onClick={() => handleDeletePaymet(row.id)}>
                <IoTrash className="vendortrash" />
              </button>
            </div>
          ),
        },
      ];


    const defaultSortField = 'Payment base';
    const defaultSortAsc = true;

    useEffect(() => {
        fetchPaymentbase(currentPage);
      }, [currentPage,searchTerm]);
    useEffect(() => {
        if (searchTerm.trim() === '') {
            setFilteredPaymentBase(paymentBase); // Show all data if search term is empty
        } else {
            const filteredData = paymentBase.filter(item =>
                item.name.toLowerCase().includes(searchTerm.toLowerCase())
            );
            setFilteredPaymentBase(filteredData);
        }
    }, [searchTerm, paymentBase]);

    const fetchPaymentbase = async (page) => {
      setLoading(true);
      try {
        let response;
        if (searchTerm) {
            response = await axiosInstance.get(`inventory/materialsegregation/search/?name=${searchTerm}`);
        } else {
            response = await axiosInstance.get(`inventory/materialsegregation/get/`);
        }
        setPaymentBase(response.data);
        setFilteredPaymentBase(response.data);       
        if (paymentBase.length === 0 && page > 1) {
            handlePageChange(page - 1);
        }
        } catch (error) {
          if (error.response && error.response.status === 404 && page > 1) {
            handlePageChange(page - 1);
          } else {
            console.error("Error fetching categories:", error);
          }
        }
        finally {
          setLoading(false);
      }
    };
    
    const handleSearchChange = (e) => {
        setSearchTerm(e.target.value);
        setCurrentPage(1);
    };
    
    const handleSubmit = async (e) => {
        e.preventDefault();
        const payload = {
          name,
          notes
        };
        setNameError1(null);
        setNotesError1(null);
        try {
          if (isEditing) {
            await axiosInstance.put(`inventory/materialsegregation/${editId}/`, payload);
            setIsEditing(false);
          } else {
            await axiosInstance.post('inventory/materialsegregation/create/', payload);
            setErrorMessage(null);
          }
      
          fetchPaymentbase(currentPage);
          resetForm();
        } catch (error) {
          console.error('Error:', error);
          if (error.response && error.response.data) {
            const nameError = error.response.data.name && Array.isArray(error.response.data.name) ? error.response.data.name[0] : null;
            const notesError = error.response.data.notes && Array.isArray(error.response.data.notes) ? error.response.data.notes[0] : null;
            const existsError = error.response.data.error && Array.isArray(error.response.data.error) ? error.response.data.error[0] : null;
            console.log(error.response.data.name);
            console.log(error.response.data.notes);
            console.log(error.response.data.error);
            console.log(existsError, 'Name Error');
            console.log(notesError, 'Description Error');
            if (nameError && notesError) {
              setNameError1(nameError)
              setNotesError1(notesError);
            }else if (nameError) {
                setNameError1(nameError)
            } else if (notesError) {
              setNotesError1(notesError);
            }else if (existsError) {
                setErrorMessage(existsError);
            }
          } else {
            setErrorMessage('An error occurred while submitting the form.');
          }
        }
    };    
    const handleDelete = async () => {
        try {
          const response = await axiosInstance.get('inventory/materialsegregation/get/');
          const allPaymentBaseData = response.data;
          const paymentBaseMap = new Map(allPaymentBaseData.map(pay => [pay.id, pay.name]));
          const selectedNames = selectedIds.map(id => paymentBaseMap.get(id)).filter(name => name);
    
          const escapeHtml = (text) => {
            return text.replace(/[&<>"']/g, (match) => {
              const escape = {
                '&': '&amp;',
                '<': '&lt;',
                '>': '&gt;',
                '"': '&quot;',
                "'": '&#039;'
              };
              return escape[match];
            });
          };
    
          const namesText = selectedNames.map(name => `<strong>${escapeHtml(name)}</strong>`).join(', ');
    
          const result = await Swal.fire({
            title: 'Are you sure?',
            html: `You are about to delete the following Material Segregation: ${namesText}. You won't be able to revert this!`,
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes, delete it!'
          });
    
          if (result.isConfirmed) {
            await axiosInstance.delete('inventory/materialsegregation/delete/', {
              data: { ids: selectedIds },
            });
            fetchPaymentbase(currentPage);
            setSelectedIds([]);
            resetForm();
            // Swal.fire('Deleted!', 'Your selected categories have been deleted.', 'success');
          }
        } catch (error) {
          console.error('Error:', error);
          Swal.fire('Error!', 'There was a problem fetching Material Segregation names or deleting the Material Segregation.', 'error');
        }
      };
    const handleUpdateClick = (paymentbase) => {
        resetForm();
        setNameError1(null);
        setNameError3(null);
        setErrorMessage(null);
        setName(paymentbase.name);
        setNotes(paymentbase.notes);
        setEditId(paymentbase.id);
        setSubmintbtn('Update');
        setIsEditing(true);
        setErrorMessage('');
    };
    
    const resetForm = () => {
        setName('');
        setNameError3(null);
        setNotes('');
        setNameError1(null);
        setNotesError1(null);
        setEditId(null);
        setSubmintbtn('Save');
        setErrorMessage('');
        setIsEditing(false);
        setClearRows(!clearRows);
    };
    const handlePageChange = (page) => {
        setCurrentPage(page);
        fetchPaymentbase(page);
      };
    const handleCheckboxChange = (selectedRows) => {
        setSelectedIds(selectedRows.selectedRows.map(row => row.id));
    };
    const handleDeletePaymet = async (id) => {
        const result = await Swal.fire({
          title: 'Are you sure?',
          text: `You won't be able to revert this!`,
          icon: 'warning',
          showCancelButton: true,
          confirmButtonColor: '#3085d6',
          cancelButtonColor: '#d33',
          confirmButtonText: 'Yes, delete it!'
        });
        if (result.isConfirmed) {
          try {
            await axiosInstance.delete(`inventory/materialsegregation/${id}/`);
            fetchPaymentbase(currentPage);
          } catch (error) {
            Swal.fire('Error!', 'There was a problem deleting the Material Segregation.', 'error');
          }
          setSelectedIds([]);
          resetForm();
        }
    };
    const validateName = (value) => {
     
      
      if(value.length === 30){
        setNameError2(null);
      }else {
        setNameError1(null); // Clear error if valid
        return true;
      }
    };

    const handleNameChange = (e) => {
      let value = e.target.value;
      value = value.replace(/\s+/g, ' ').trimStart();
     
        
        setName(value);
        validateName(value);
    };

    
    const handleEnter = (event) => {
      if (event.target && nameError1 && (event.key === 11 || event.key == 13)) {
        event.target.focus();  // Focuses if there's a name error and enter key is pressed
      } else if (event.keyCode === 13 && event.target.form) {
        const form = event.target.form;
        const index = Array.prototype.indexOf.call(form.elements, event.target);
        const nextElement = form.elements[index + 1];
        if (nextElement) {
            // Focuses the next form element if available
          console.log(nextElement,'fkihwjuih');
          nextElement.focus();
        }
        if (event.target.tagName === 'INPUT' || event.target.tagName === 'TEXTAREA') {
          event.target.value = event.target.value.trim();  // Ensures the value is trimmed properly
        }
        event.preventDefault();
      }
      const { value } = event.target;
    
      // Prevent leading, trailing, or consecutive spaces
      if (
        (event.key === ' ' && value.length === 0) || // Prevent space at the start
        (event.key === ' ' && value.endsWith(' ')) || // Prevent space at the end
        (event.key === ' ' && value.includes('  ')) // Prevent consecutive spaces
      ) {
        event.preventDefault();
        return; // Exit the function early if the space conditions are met
      }
    };
    const handleArrowNavigation = (event, buttonType) => {
      if (event.key === 'ArrowRight' && buttonType === 'save' && cancelButtonRef.current) {
        cancelButtonRef.current.focus();
      } else if (event.key === 'ArrowLeft' && buttonType === 'cancel' && submitButtonRef.current) {
        submitButtonRef.current.focus();
      }
    };
    
const handleBlur = (e) => {
  setNameError2(null);
  setDescriptionError2(null);
  if (e.target.tagName === 'INPUT' || e.target.tagName === 'TEXTAREA') {
    e.target.value = e.target.value.trim();  // Ensures the value is trimmed properly
  }
  if (nameError1) {
      e.target.focus();
  }else if (descriptionError1) {
    e.target.focus(); 
  }

};
const handleDescriptionFocus = () => {
// Perform name validation when switching to the description field
if(isEditing){
  const otherName = paymentBase.filter(names => names.id !== editId);
  console.log(otherName,'is editing');
  if (Array.isArray(otherName) && otherName.some((item) => item.name.replace(/\s+/g,'').toLowerCase() === name.replace(/\s+/g,'').trim().toLowerCase())) {
    setShouldFocusName(true);
    setNameError3('Materrial Segregation with this name already exists.');
    setTimeout(() => {
      const nameInput = document.getElementById('nameInput');
      if (nameInput) {
        nameInput.focus(); // Set focus on the input with id 'nameInput'
      }
    }, 0);
  }
  else {
    setNameError3(null);
  }
  
}else{
  if(editId === null && paymentBase.some((item) => item.name.replace(/\s+/g,'').toLowerCase() === name.replace(/\s+/g,'').toLowerCase())){
    setShouldFocusName(true);
    setNameError3('Material Segregation with this name already exists.');
    setTimeout(() => {
      const nameInput = document.getElementById('nameInput');
      if (nameInput) {
        nameInput.focus(); // Set focus on the input with id 'nameInput'
      }
    }, 0);
      return false;
    }else {
      setNameError3(null);
    }
}
};


  const customStyles = {
    header: {
      style: {
        minHeight: '56px', // Adjust header height
        backgroundColor: '#f0f0f0', // Light grey background for the header
        color: '#333', // Text color
      },
    },
    headRow: {
      style: {
        backgroundColor: '#f0f0f0', // Grey color for the table header row
      },
    },
    headCells: {
      style: {

        fontSize: '14px', // Font size for header cells
        fontWeight: 'bold',
        textAlign: 'left',
        color: '#000', // Header text color
      },
    },
    rows: {
      style: {
        minHeight: '48px', // Minimum height of rows
        borderBottom: '1px solid #ddd', // Bottom border for rows
      },
    },
    cells: {
      style: {
        textAlign: 'left',
      },
    },
  };
  
    return(
        <Fragment>
            <Breadcrumbs mainTitle="Material Segregation" parent="Inventory" title="Material Segregation"/>
            <Container fluid={true} className='Container1'>
                <div className='vendordiv1'>
                    <Col sm="12" xl="6" className='vendorcol1'>
                        <Card className=''>
                            <div style={{ display: "flex" }}>
                                <div className='headercl'>
                                <HeaderCard title="Material Segregation List" />
                                </div>
                                <div className='selectdel' style={{ display: "flex", alignItems: "center", justifyContent: "flex-end", width: "40%" }}>
                                    <Button color="danger" className='btncon' onClick={handleDelete} disabled={selectedIds.length === 0}><IoTrash className="vendortrash1" />Delete</Button>
                                </div>
                            </div>
                        <CardBody>
                            <div className="table-responsive">
                                <InputGroup className="mb-3">
                                    <Input
                                        type="text"
                                        placeholder="Search"
                                        value={searchTerm}
                                        onChange={handleSearchChange}
                                        className="form-control input-no-animation inputfoc"
                                        style={{borderColor: "#afafaf" }}
                                    />
                                    <InputGroupText >
                                        <FaSearch />
                                    </InputGroupText>
                                </InputGroup>
                                <DataTable
                                    columns={columns}
                                    data={paginatedData}
                                    selectableRows
                                    onSelectedRowsChange={handleCheckboxChange}
                                    clearSelectedRows={clearRows}
                                    pagination
                                    paginationServer
                                    striped={false}
                                    paginationTotalRows={filteredPaymentBase.length}

                                    paginationPerPage={rowsPerPage}
                                    progressPending={loading}
                                    progressComponent={<div>Loading...</div>}
                                    paginationComponentOptions={{
                                    noRowsPerPage: true, // Disable option to change rows per page
                                    }}
                                    onChangePage={handlePageChange} // Handle pagination
                                    highlightOnHover
                                    customStyles={customStyles} // Add the custom styles here
                                    defaultSortFieldId={defaultSortField}
                                    defaultSortAsc={defaultSortAsc}

                                />
                            </div>
                            
                            {/* {console.log(paginationTotalRows)} */}
                        </CardBody>
                        </Card>
                    </Col>
                    <Col  sm="12" xl="6" className='vendorcol2'>
                        <Card style={{ width: "auto" } }>
                            <HeaderCard title={"Material Segregation"}/>
                            <CardBody>
                                <Form className="theme-form" onSubmit={handleSubmit}>
                                    <FormGroup>
                                        <Label style={{fontWeight:"600"}}>Material Segregation</Label>
                                        <Input autoComplete='off' style={{color:"black" ,borderColor: "#afafaf" }} id='nameInput' onBlur={handleBlur} onKeyDown={handleEnter} className={classNames("form-control", "inputfoc", {"is-invalid": nameError1,})} type="text" value={name} onChange={handleNameChange} maxLength="30" required/>
                                    </FormGroup>
                                    {nameError1 && (
                                        <div className="alert alert-danger">
                                            {nameError1}
                                        </div>
                                    )}
                                    {nameError2 && (
                                        <div className="alert alert-danger">
                                            {nameError2}
                                        </div>
                                    )}
                                    {nameError3 && (
                                        <div className="alert alert-danger">
                                            {nameError3}
                                        </div>
                                    )}
                                    <FormGroup>
                                        <Label style={{fontWeight:"600"}}>Notes</Label>
                                        <Input onFocus={handleDescriptionFocus} type="textarea" onBlur={handleBlur} onChange={(e)=>setNotes(e.target.value)} onKeyDown={handleEnter} className={classNames("form-control", "inputfoc1", {"is-invalid": notesError1,})} rows="3" style={{color:"black", borderColor: "#afafaf"}} value={notes}  maxLength={250} />
                                    </FormGroup>
                                    {notesError1 && (
                                        <div className="alert alert-danger">
                                            {notesError1}
                                        </div>
                                    )}
                                    {errorMessage && (
                                        <div className="alert alert-danger">
                                            {errorMessage}
                                        </div>
                                    )}
                                    <div className="mt-3" style={{gap:"10px",display:"flex", justifyContent:"flex-end"}}>
                                        <button color='primary' type='submit' className='btn2' ref={submitButtonRef} onKeyDown={(event) => handleArrowNavigation(event, 'save')}   style={{width:'110px'}} disabled={nameError1 || nameError2 || nameError3 || notesError1 || (name === '')}>
                                            {submitbtn}
                                        </button>
                                        <button className='btn1'  ref={cancelButtonRef} onKeyDown={(event) => handleArrowNavigation(event, 'cancel')} onClick={resetForm} >
                                            Cancel
                                        </button>
                                       
                                    </div>
                                </Form>
                            </CardBody>
                        </Card>
                    </Col>
                </div>
            </Container>
        </Fragment>
    );
}
export default MaterialSegregation;
