import React, { Fragment, useState, useEffect, useContext } from 'react';
import { Container, Form, FormGroup, Input, Label } from 'reactstrap';
import { Btn, H4, P } from '../../../AbstractElements';
import { EmailAddress, ForgotPassword, LoginWithJWT, Password, RememberPassword, SignIn } from '../../../Constant';

import { useNavigate } from 'react-router-dom';
import { Jwt_token } from '../../../Config/Config';
import man from '../../../assets/images/dashboard/profile.png';
import { handleResponse } from '../../../Services/fack.backend';
import Swal from 'sweetalert2';

import CustomizerContext from '../../../_helper/Customizer';
import axios from 'axios';
import axiosInstance from '../../AxiosInstance';


const LoginTab = ({ selected }) => {
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [togglePassword, setTogglePassword] = useState(false);
  const history = useNavigate();
  const { layoutURL } = useContext(CustomizerContext);
  const[errorMessage,setErrorMessage] = useState(null);
  const [value, setValue] = useState(localStorage.getItem('profileURL' || man));
  const [name, setName] = useState(localStorage.getItem('Name'));

  useEffect(() => {
    localStorage.setItem('profileURL', man);
    localStorage.setItem('Name', 'Emay Walter');
  }, [value, name]);

  const loginAuth = async (e) => {
    e.preventDefault();
    try {
      const response = await axios.post('https://kannant.pythonanywhere.com/api/token/', {
        username,
        password
      });

      // Assuming the backend returns the token in the 'access' field
      const { access, refresh } = response.data;

      // Store the token in sessionStorage
      sessionStorage.setItem('accessToken', access);
      sessionStorage.setItem('refreshToken', refresh);

      console.log('Login successful, token stored in sessionStorage:');
      history(`/vendorcategory`);

    } catch (error) {
      console.error('Login failed:', error);
      Swal.fire('Failed!', 'Email or Password is wrong','error');
    }

    setValue(man);
    setName('Emay Walter');
    // if (email === 'test@gmail.com' && password === 'test123') {
    //   localStorage.setItem('login', JSON.stringify(true));
    //   history(`/vendorcategory`);
    // }else{
    //   Swal.fire('Failed!', 'Email or Password is wrong','error');
    // }
  };

  const loginWithJwt = (e) => {
    const requestOptions = {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: { username, password },
    };

    return fetch('/users/authenticate', requestOptions)
      .then(handleResponse)
      .then((user) => {
        // store user details and jwt token in local storage to keep user logged in between page refreshes
        setValue(man);
        setName('Emay Walter');
        localStorage.setItem('token', Jwt_token);
        window.location.href = `${process.env.PUBLIC_URL}/pages/sample-page/${layoutURL}`;
        return user;
      });
  };

  return (
    <Fragment>
      <Container>
      <Form className='theme-form' >
        <H4>{selected === 'simpleLogin' ? 'Sign In With Simple Login' : 'Sign In With Jwt'}</H4>
        <P>{'Enter your username & password to login'}</P>
        <FormGroup>
          <Label className='col-form-label'>Username</Label>
          <Input className='form-control' type='text' onChange={(e) => setUsername(e.target.value)} value={username} />
        </FormGroup>
        <FormGroup className='position-relative'>
          <Label className='col-form-label'>{Password}</Label>
          <div className='position-relative'>
            <Input className='form-control' type={togglePassword ? 'text' : 'password'} onChange={(e) => setPassword(e.target.value)} value={password} />
            <div className='show-hide' onClick={() => setTogglePassword(!togglePassword)}>
              <span className={togglePassword ? '' : 'show'}></span>
            </div>
          </div>
        </FormGroup>
        <div className='position-relative form-group mb-0'>
          <div className='checkbox'>
            <Input id='checkbox1' type='checkbox' />
            <Label className='text-muted' for='checkbox1'>
              {RememberPassword}
            </Label>
          </div>
          <a className='link' href='#javascript'>
            {ForgotPassword}
          </a>
          {errorMessage && (
              <div className="alert alert-danger">
                  {errorMessage}
              </div>
          )}
          {selected === 'simpleLogin' ? (
            <Btn attrBtn={{ color: 'primary', className: 'd-block w-100 mt-2', onClick: (e) => loginAuth(e) }}>{SignIn}</Btn>
          ) : (
            <Btn attrBtn={{ color: 'primary', className: 'd-block w-100 mt-2', onClick: (e) => loginWithJwt(e) }}>{LoginWithJWT}</Btn>
          )}
        </div>
        {/* <OtherWay /> */}
      </Form>
      </Container>
    </Fragment>
  );
};

export default LoginTab;
